import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby';

import RenderTeamWrapper from 'components/HOC/RenderTeamWrapper'
import SlicesWrapper from 'components/slices'
import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const Team = ({ data, preview, location, pageContext }) => {
  const language = preview ? data.prismicAboutTeam.lang : pageContext.lang;
  const alternateLanguages = preview ? data.prismicAboutTeam.alternate_languages : pageContext.alternateLanguages;
  const content = data.prismicAboutTeam.data;

  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  //Hero Image default banner image
  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;
  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;
  const sideBarProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);

  //language codes from header query for SEO component
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);

  let headData = {
    meta_title: content.meta_title,
    meta_description: content.meta_description,
    meta_keywords: content.meta_keywords,
  }

  // add open graph meta data if available
  if(content?.body2?.length > 0) {
    const filteredOpenGraphData = content.body2.filter(el => el.slice_type === "open_graph");

    if (filteredOpenGraphData?.length > 0) {
      const openGraphData = filteredOpenGraphData[0]?.primary;

      if (openGraphData?.og_title) {
        headData = {
          ...headData,
          og_title: openGraphData.og_title,
          og_description: openGraphData?.og_description || openGraphData?.og_title,
          og_image: openGraphData?.og_image?.url.split('?')[0] || defaultBannerImage.url.split('?')[0],
          og_type: openGraphData?.og_type || 'website',
        }
      }
    }
  }

  return (
    <RenderTeamWrapper 
      heroImage={content.hero_image}
      preview={preview}
      content={content}
      leadership={content.body1[0]}
      meta={headData}
      location={location}
      language={language}
      alternateLanguages={alternateLanguages}
      languageCodes={languageCodes}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      defaultBannerImage={defaultBannerImage}
      headerData={headerData}
      sideBarProps={sideBarProps}
      footerProps={footerProps}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      universalProps={data.allPrismicUniversalProperties.edges}
      icons={data.allPrismicUniversalProperties.edges}
      component={
        <SlicesWrapper
          slices={content.body1.slice(1)} 
          preview={preview} 
          alignLeft={true}
        />
      }
    />
  )
}

Team.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
}

export default Team

export const query = graphql`
  query TeamPageQuery($id: String!) {
    prismicAboutTeam(id: { eq: $id }) {
      ...Team
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          lang
          data {
            phone_local
            localised_phone {
              country_code
              phone_number
            }
          }
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
  }
`
