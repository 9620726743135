import Prismic from 'prismic-javascript'
import axios from 'axios'

export function getAllAircraft(self){
    const apiEndpoint = 'https://PrivateFly.prismic.io/api/v2';

    Prismic.api(apiEndpoint).then(api => {
        return api.query(
          Prismic.Predicates.at('document.type', 'aircraft'), { pageSize: '100', lang: '*' }
        ).then(res => {
            let promises = [];
            let allresults = [];
  
                for (let i=1; i <= res.total_pages; i++){
                    promises.push(api.query(
                        Prismic.Predicates.at('document.type', 'aircraft'), { pageSize: '100', page: i, lang: '*' }
                     ))
                }
                axios.all(promises).then(res => {
                    res.forEach(item => {
                        allresults = allresults.concat(item.results)
                        // allresults.push(item.results)
                    })
                    self.setState({
                        allAircraft: allresults
                    })
                })
        })
    })
}