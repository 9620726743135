import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SlicesWrapper from 'components/slices/containers/destinations';

import RenderProductPageWrapper from 'components/HOC/RenderProductPageWrapper';
import HomepageFlightSearch from 'components/flightSearch/container'; 

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const DestinationsIndexPage = ({ data, preview, location, pageContext }) => {
  const [ cardsToShow, showMoreCards ] = useState(4);

  const language = preview ? data.prismicDestinations.lang : pageContext.lang;

  let alternateLanguages = preview ? data.prismicDestinations.alternate_languages : pageContext.alternateLanguages;

  const prismicData = data.prismicDestinations.data;
  const slices = prismicData.body;

  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);
  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);

  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const sideBarProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);

  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;

  let headData = {
    meta_title: prismicData.meta_title,
    meta_description: prismicData.meta_description,
    meta_keywords: prismicData.meta_keywords,
  };

  // add open graph meta data if available
  if(prismicData?.body2?.length > 0) {
    const filteredOpenGraphData = prismicData.body2.filter(el => el.slice_type === "open_graph");

    if (filteredOpenGraphData?.length > 0) {
      const openGraphData = filteredOpenGraphData[0]?.primary;

      if (openGraphData?.og_title) {
        headData = {
          ...headData,
          og_title: openGraphData.og_title,
          og_description: openGraphData?.og_description || openGraphData?.og_title,
          og_image: openGraphData?.og_image?.url.split('?')[0] || '',
          og_type: openGraphData?.og_type || 'website',
        }
      }
    }
  }

  const handleCardsPagination = () => {
    showMoreCards(prevState => {
      return prevState + 12
    })
  }

  return (
    <RenderProductPageWrapper
      alternateLanguages={alternateLanguages}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      footerProps={footerProps}
      headerData={headerData}
      icons={data.allPrismicUniversalProperties.edges}
      location={location}
      language={language}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      languageCodes={languageCodes}
      meta={headData}
      preview={preview}
      universalProps={data.allPrismicUniversalProperties.edges}
      component={ 
        <SlicesWrapper 
          slices={slices} 
          cardsToShow={cardsToShow}
          preview={preview}
          handleCardsPagination={handleCardsPagination}
          language={language}
          flightSearchComponent={
            <HomepageFlightSearch 
              isHomePage={false}
              language={language}
              errorMessages={errorMessages}
              sideBarProps={sideBarProps}
            />
          }
        />
      }
    />
  )
}

export default DestinationsIndexPage

DestinationsIndexPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
}

export const query = graphql`
  query DestinationsIndexQuery($id: String!) {
    prismicDestinations(id: { eq: $id }) {
        id
        lang
        data {
          meta_title
          meta_keywords
          meta_description
          body2 {
            ... on PrismicDestinationsBody2OpenGraph {
              slice_type
              primary {
                og_title
                og_description
                og_type
                og_image {
                  dimensions {
                    height
                    width
                  }
                  url
                }
              }
            }
          }
          body {
            ... on PrismicDestinationsBodyCountryList {
              id
              slice_type
              primary {
                section_title {
                  raw {
                    text
                    type
                  }
                }
              }
              items {
                card_title {
                  raw {
                    text
                    type
                  }
                  text
                }
                card_link {
                  url
                }
                card_image {
                  url
                  alt
                }
                card_description {
                  raw {
                    text
                    type
                  }
                }
              }
            }
            ... on PrismicDestinationsBodyBannerImage {
              id
              slice_type
              primary {
                banner_image_size
                display_banner_at_the_bottom
                display_heading1_as_paragraph
                heading1 {
                  raw {
                    text
                    type
                  }
                }
                text_position
                image {
                  dimensions {
                    height
                    width
                  }
                  url
                  mobile {
                    dimensions {
                      height
                      width
                    }
                    url
                  }
                }
              }
            }
            ... on PrismicDestinationsBodyDestinationList {
              id
              slice_type
              primary {
                section_title {
                  raw {
                    text
                    type
                  }
                }
              }
              items {
                card_description {
                  raw {
                    text
                    type
                  }
                }
                card_image {
                  alt
                  url
                }
                card_link {
                  url
                }
                card_title {
                  raw {
                    text
                    type
                  }
                  text
                }
              }
            }
            ... on PrismicDestinationsBodyFlightSearch {
              id
              slice_type
              primary {
                flight_search {
                  id
                }
              }
            }
            ... on PrismicDestinationsBodyRichtext {
              id
              slice_type
              primary {
                text {
                  raw {
                    text
                    type
                  }
                }
              }
            }
          }
        }
      }
      allPrismicFooterV2 {
        edges {
          node {
            ...FooterData
          }
        }
      }
      allPrismicHeadermenuV2 {
        edges {
          node {
            ...HeaderData
          }
        }
      }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }

    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    }
`
