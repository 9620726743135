import Prismic from 'prismic-javascript';

import createProperties from 'utils/functions/createProperties';

const fetchInspirationalDocuments = () => {
  const { prismicEndpoint } = createProperties();
  
  const client = Prismic.client(prismicEndpoint);

  return Prismic
    .api(prismicEndpoint)
    .then(api => api.query(Prismic.Predicates.at('document.type', 'inspirational'), { pageSize: '100', lang: '*' }) )
    .then(response => {
      const pages = response.total_pages;

      const pagesNumber = [...Array(pages).keys()];
  
      return Promise.all(pagesNumber.map((el, i) => {
        const page = i +1
  
        return client
          .query(Prismic.Predicates.at('document.type', 'inspirational'), { pageSize: '100', page: page, lang: '*' })
          .then(res => res.results)
          .catch(err => console.log(err))
      }))
      .then(array => [].concat(...array))
      .then(result => {
        return result.map(el => { return { node: el } }) 
      })
    })
    .catch(err => console.log(err))
}

export default fetchInspirationalDocuments;