import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby";

import InternalLink from 'components/links/internalLink';

import { 
  StyledCountryTableBody, 
  StyledCountryTableRow, 
  StyledCountryTableCell 
} from 'components/tables/styled';

const CountryIndexPageTable = ({ content, language }) => {
  const data = useStaticQuery(graphql`
    query countryIndexTableQuery {
      allCountryData(sort: {fields: [country_name], order: ASC }){
        edges {
          node {
            id
            country_name
            country_code
            iso_country_code
          }
        }
      }
    }
  `)

  const allCountries = data.allCountryData.edges;

  return (
    <table>
      <StyledCountryTableBody>
        {allCountries.map(el => (
          <StyledCountryTableRow>
            <StyledCountryTableCell>
              <InternalLink 
                path={ `/${content.url}/${el.node.country_name.replace(/\s/g, '-')}-${el.node.id}` }
                linkText={el.node.country_name}
                language={language}
              />
            </StyledCountryTableCell>
          </StyledCountryTableRow>
          ))
        }
      </StyledCountryTableBody>
    </table>
  )
}

CountryIndexPageTable.propTypes = {
  content: PropTypes.object,
  language: PropTypes.string,
}

export default CountryIndexPageTable
