exports.switchLanguage = (prismicDocumentsArray, language) => {
  let uniqArray = []
  let localizedArray = [];
  let defaultGBArray = [];
  let finalArray = [];

  const localeRegex = /(fr-fr)|(en-gb)/gi

  const locale = process.env.LANG_CODE || language;

  if (!locale.match(localeRegex)) {
    localizedArray = prismicDocumentsArray.filter(el => el.node.lang === locale);
    defaultGBArray = prismicDocumentsArray.filter(el => el.node.lang === "en-gb");
    finalArray = defaultGBArray.concat(localizedArray)
  } else {
    finalArray = prismicDocumentsArray.filter(el => el.node.lang === locale);
  }

  uniqArray = Array.from(new Map(finalArray.map(e => [e.node.data.url, e])).values());

  return uniqArray;
};