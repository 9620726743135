import styled from 'styled-components';

export const SpecsSection = styled.section`
  margin: 25px 0px;

  h3 {
    font-weight: 400 !important;
    font-size: 24px !important;
    font-family: "Raleway" !important;
  }
`;

export const StyledSpecificationsTable = styled.table`
  display: flex;
  flex-wrap: wrap;
  background: #F7F7F7;
  padding: 20px 0;

  tr {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: solid thin #c4c0c6;
    padding: 10px 15px;
    margin: 0 15px;
    
    @media (min-width: 850px) {
      width: 33%;
      flex-direction: column;
      align-items: normal;
      margin: unset;
      padding-bottom: 20px;
      padding-left: 15px;
      border-right: solid thin #c4c0c6;
      border-bottom: none;
      &:nth-of-type(3n) {
        border-right: none;
        padding-right: 0;
      }
      &:last-child {
        border-bottom: none;
      }
      &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
        border-bottom: none;
      };
      &:nth-of-type(7), &:nth-of-type(8), &:nth-of-type(9) {
        padding-bottom: 0;
      }
    }
  }

  .aircraft-specification-table--name-cell {
    display: inline-block;
    border: 0;
    min-width: 46%;
    font-family: "Open Sans";
    font-size: 10px;
    font-weight: 600;
    color: #636463;
    text-transform: uppercase;
    @media (min-width: 850px) {
      min-width: 155px;
    }
  }
  
  .aircraft-specification-table--value-cell {
    display: inline;
    border: 0;
    font-size: 14px;
    color: #3C3C3B;
    font-family: "Open Sans";
    font-weight: 400;
  }
`;