exports.switchLanguage = (prismicDocumentsArray, homepage) => {
  let uniqArray = []
  let localizedArray = [];
  let defaultGBArray = [];
  let finalArray = [];
  let localeRegex = /(fr-fr)|(en-gb)/gi

  const locale = process.env.LANG_CODE;

  if (!locale.match(localeRegex)) {
    localizedArray = prismicDocumentsArray.filter(el => el.node.lang === locale);
    defaultGBArray = prismicDocumentsArray.filter(el => el.node.lang === "en-gb");
    finalArray = defaultGBArray.concat(localizedArray)
  } else {
    finalArray = prismicDocumentsArray.filter(el => el.node.lang === locale);
  }
  
  //remove any duplicate documents

  if (homepage) {
    uniqArray = Array.from(new Map(finalArray.map(e => [e.node.uid, e])).values());
  } else {
    uniqArray = Array.from(new Map(finalArray.map(e => [e.node.data.url, e])).values());
  }

  return uniqArray;
};