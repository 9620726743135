import React, {useState } from 'react';
import PropTypes from 'prop-types';
import Link from "gatsby-link";

import ArrowButton from 'components/buttons/ArrowButton';
import { StyledBlogCategoriesList, StyledBlogSmallHeading } from 'components/blog/styled';

const isFR = process.env.LANG_CODE === 'fr-fr';

const Archive = ({ data, heading }) => {

  const [monthLinks, setMonthLinks] = useState(8);
  const showLinks = (showMore) => { setMonthLinks(monthLinks + showMore) };

  const monthsArr = [];
  let monthObj = {};

  const archivedDocuments = data.map(el => el.fields && el.fields.month).filter(el => el !== undefined)
   
  archivedDocuments.forEach(x => {
    monthObj[x] = (monthObj[x] || 0) + 1; 
  });

  const sortedMonthsArray = Object.keys(monthObj).sort((a, b) => { 
    return a-b 
  });
  
  sortedMonthsArray.map((el, i) => {
    const obj = {};

    obj.month= sortedMonthsArray[i];
    obj.documentCount= monthObj[sortedMonthsArray[i]];
    
    monthsArr.push(obj);
  })
    
  return (
    <div>
      <StyledBlogSmallHeading> { heading.list_heading } </StyledBlogSmallHeading>
      <StyledBlogCategoriesList>
        {monthsArr.splice(0, monthLinks).map(archiveDate => {  
          // convert archiveDate.month to a month name
          const currentYear = new Date().getFullYear();
          const createDate = `${currentYear}-${archiveDate.month}-01`;
          const dateOptions = { day: '2-digit', month: 'long', year: 'numeric' };
          const datePublished = new Date(createDate).toLocaleDateString( isFR ? "fr-fr" : 'en-gb', dateOptions);
          const datePublishedArray = datePublished.split(' ');
          const monthPublished = datePublishedArray[1];

          // number of documents in each month
          const documentCount = archiveDate.documentCount;

          return (
            <li key={archiveDate.month}>
              <Link to={`/${archiveDate.month}`}> {monthPublished} </Link> ({documentCount})
            </li>
          );
        })}
        { monthLinks < sortedMonthsArray.length && (
          <ArrowButton 
            buttonText={`${isFR ? "AFFICHER PLUS" : "SHOW ALL"} ${heading.list_heading}`}
            theme="blue"
            isLink={false}
            onClick={()=>showLinks(sortedMonthsArray.length)}
            style={{justifyContent: 'right'}}
          /> 
        )}
      </StyledBlogCategoriesList>
    </div>
  );
};

Archive.propTypes = {
  data: PropTypes.object,
  heading: PropTypes.object
};

export default Archive;