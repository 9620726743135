import Prismic from "prismic-javascript";

import createProperties from 'utils/functions/createProperties';

// 96824 - this file incl preview changes

const fetchFeaturedInspirationalDocuments = (prismicDocument) => {
  const { prismicEndpoint } = createProperties();

  if (prismicDocument.data.type === "inspirational-index-page") {
    let documentIds = [];

    prismicDocument.data.data.data.featured_inspirational_cards.map(article => {
      documentIds.push(article.featured_inspirational_article.id);
    });

    return Prismic
      .api(prismicEndpoint)
      .then(api => api.getByIDs(documentIds))
      .then(res => {
        return res.results.map(el => { return {featured_inspirational_article : { document: [ el ] }} })
      })
  }

  if (prismicDocument.data.type === "index_inspirati") {
    let documentIds = [];
    let featuredInspirationalData = [];
    
    prismicDocument.data.data.data.body.map((el)=>{
      if(el.slice_type === "inspirational_featured_list") {
        el.items.map((el)=>{
          documentIds.push(el.featured_link.id);
       })
      }
    })

    return Prismic
      .api(prismicEndpoint)
      .then(api => api.getByIDs(documentIds))
      .then(res => {
        res.results.map((el) => featuredInspirationalData.push(el.data) )
        
        return featuredInspirationalData
      })
  }

};

export default fetchFeaturedInspirationalDocuments;
