import React from 'react'
import PropTypes from 'prop-types'

import GallerySlice from '../slices'
import RichPost from 'components/richText';
import AircraftSpecs from './AircraftSpecs';
import KeyFeatures from 'components/aircraft/KeyFeatures';

import { AircraftTemplateColumn } from 'components/grid/styled'

const Aircraft = ({
  richContent,
  content,
  gallerySlice,
  filteredSlices,
  preview,
  aircraftId,
  language,
  aircraftProps,
  aircraftSpecifications,
  isAircraftTemplate,
}) => {

  // key features slice (this shows when screen width < 1200px)
  const aircraftKeyFeaturesWidget = isAircraftTemplate && filteredSlices && filteredSlices.filter(slice => slice.slice_type === 'widget');

  return (
    <React.Fragment>
      <AircraftTemplateColumn
        mobileOrder={0}
        desktopOrder={0}
        width='100%'
        mobileWidth="100%"
      >
        
        <RichPost 
          richContent={richContent} 
          slices={filteredSlices}
          preview={preview}
          language={language}
          isAircraftTemplate={isAircraftTemplate}
        />

        { isAircraftTemplate && aircraftKeyFeaturesWidget && aircraftKeyFeaturesWidget.length > 0 && (
          <KeyFeatures 
            slice={aircraftKeyFeaturesWidget[0]} 
            preview={preview}
          />
        )}
        <AircraftSpecs
          content={content}
          aircraftId={aircraftId}
          aircraftProps={aircraftProps}
          preview={preview}
          aircraftSpecifications={aircraftSpecifications}
        /> 
        <GallerySlice  
          slices={gallerySlice} 
          preview={preview}
          props={aircraftProps}
        />
      </AircraftTemplateColumn>
    </React.Fragment>
  )
}

export default Aircraft

Aircraft.propTypes = {
  aircraftId: PropTypes.string,
  aircraftProps: PropTypes.object,
  aircraftSpecifications: PropTypes.object,
  content: PropTypes.object,
  filteredSlices: PropTypes.array,
  gallerySlice: PropTypes.array,
  isAircraftTemplate: PropTypes.bool,
  language: PropTypes.string,
  preview: PropTypes.bool,
  richContent: PropTypes.array.isRequired,
}