import styled from 'styled-components';

export const StyledKeyFeaturesWrapper = styled.section`
  margin: 25px 0px;

  li {
    color: #636463
  }
  @media(min-width: 1200px) {
    display: none;
  }
`