import React from 'react';
import PropTypes from 'prop-types';

import { StyledSpecificationsTable, SpecsSection } from 'components/aircraft/styled';
import { H3 } from 'styled';

const AircraftSpecs = ({ aircraftProps, preview, aircraftSpecifications }) => {
  if (aircraftSpecifications) {

    let aircraftSpecificationsArray = Object.entries(aircraftSpecifications.node.specs_list).map(([key, value]) => ({key, value}));
  
    const modifiedArray = aircraftSpecificationsArray.map((item, index) => {
      if (item.key === "luggage_capacity") item = {...item, value: item.value?`${item.value} cu.ft.`:'N/A'}
      if (item.key === "cruising_speed") item = {...item, value: `${item.value} kts`}
      if (item.key === "cabin_height") item = {...item, value: `${item.value} m`}
      if (item.key === "cruising_range") item = {...item, value: `${item.value} nm`}
      if (item.key === "cabin_width") item = {...item, value: `${item.value} m`}

      return item
    });

    return (
      <SpecsSection>
        <a 
          id="specification" 
          style={{marginTop: '-80px', position: 'absolute'}} 
        />

        <H3> { aircraftProps.specification_title.text } </H3>
        
        <StyledSpecificationsTable>
          {aircraftProps.spec_names.map((el, i) => {
            return modifiedArray.map((item, index) => {
              if (i === index) {
                return (
                  <tr>
                    <td className="aircraft-specification-table--name-cell">{el.spec_title}</td>
                    <td className="aircraft-specification-table--value-cell">{item.value}</td>
                  </tr>
                )
              }
            })
          })}
        </StyledSpecificationsTable>
      </SpecsSection>
    )
  } else return null
}

export default AircraftSpecs;

AircraftSpecs.propTypes = {
  aircraftProps: PropTypes.object, 
  aircraftSpecifications: PropTypes.object, 
  preview: PropTypes.bool
};
