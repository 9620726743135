import React from 'react';
import PropTypes from 'prop-types';

import SidebarFilters from 'components/blog/filters';
import Banner from 'components/banner';

import { StyledBlogsLargeColumnWrapper, StyledBlogsContainer } from 'components/blog/styled';
import { FullWidthCardContainer } from 'components/grid/styled';

import RenderRichText from 'utils/richText/renderRichText';

function BlogArticles({ universalBlogProps, articleComponent, bannerImageSlice, preview}) {

  // banner variables
  const banner = bannerImageSlice ? bannerImageSlice : universalBlogProps.node.data.body[0];
  const bannerImageSrc = banner.primary.image.url;
  const displayHeadingOneAsParagraph = banner.primary.display_heading1_as_paragraph
  const textPosition = banner.primary.text_position;
  const headingOneRichText = preview ? banner.primary?.heading1 : banner.primary?.heading1?.raw;
  const headingTwoRichText = preview ? banner.primary?.heading2 : banner.primary?.heading2?.raw;
  const headingThreeRichText = preview ? banner.primary?.heading3 : banner.primary?.heading3?.raw;
  const paragraphRichText = preview ? banner.primary?.banner_text : banner.primary?.banner_text?.raw;
  const isBottomBanner = banner.primary.display_banner_at_the_bottom;
  const bannerImageSize = banner.primary.banner_image_size;

  return (
    <React.Fragment>

      <Banner 
        desktopLarge={bannerImageSize === "large" ? true: false}
        desktopMedium={bannerImageSize === "medium" ? true: false}
        desktopSmall={bannerImageSize === "small" ? true: false}
        isHomePage={false}
        headingOne={headingOneRichText && <RenderRichText richTextArray={headingOneRichText} />}
        headingTwo={headingTwoRichText && <RenderRichText richTextArray={headingTwoRichText} />}
        headingThree={headingThreeRichText && headingThreeRichText && <RenderRichText richTextArray={headingThreeRichText} />}
        displayHeadingOneAsParagraph={displayHeadingOneAsParagraph}
        textPosition={textPosition}
        bannerImageSrc={bannerImageSrc}
        text={paragraphRichText && <RenderRichText richTextArray={paragraphRichText} />}
        isBottomBanner={isBottomBanner}
        buttonComponent={null}
      />

      <FullWidthCardContainer>
        <StyledBlogsContainer isArticle={true}>

          <StyledBlogsLargeColumnWrapper> {articleComponent} </StyledBlogsLargeColumnWrapper>
          <SidebarFilters showHomepageLink={true} /> 
        
        </StyledBlogsContainer>
      </FullWidthCardContainer>

    </React.Fragment>
  )
};

BlogArticles.propTypes = {
  articleComponent: PropTypes.object,
  bannerImageSlice: PropTypes.object,
  preview: PropTypes.bool,
  universalBlogProps: PropTypes.object
};

export default BlogArticles;