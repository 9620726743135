import Prismic from "prismic-javascript";

import createProperties from 'utils/functions/createProperties';

// 96824 - this file incl preview changes

const fetchWidgetData = (prismicDocument) => {
   const { prismicEndpoint } = createProperties();

   let documentIds = [];

   const getWidgetBySlice = (docs) => {
      return Prismic
      .api(prismicEndpoint)
      .then(api => api.getByIDs(docs))
      .then(res => {
         return res.results
      })
   }

   const getWidgetFromProperties = (prismicDocument, property) => {
      return Prismic
      .api(prismicEndpoint)
      .then(api => {
         return api.query(Prismic.Predicates.at('document.type', property), { pageSize: '5', lang: prismicDocument.data.data.lang || '*' })
      })
      .then(res => {
         if(res.results.length > 0) {
            if(res.results[0].data.body.length > 0) {
               
               res.results[0].data.body.map((el)=>{
                  if(el.slice_type === "widget") documentIds.push(el.primary.widget_document.id);
               })

               return getWidgetBySlice(documentIds);
            }
         }
      })
   }

   // FETCH WIDGET DATA
   switch (prismicDocument.data.type) {
      case "product": 
      case "index_page_press_and_media": 
         prismicDocument.data.data.data.body.map((el)=>{
            if(el.slice_type === "widget") documentIds.push(el.primary.widget_document.id);
         })
         return getWidgetBySlice(documentIds);
         break;

      case "other": 
         prismicDocument.data.data.data.body1.map((el)=>{
            if(el.slice_type === "widget") documentIds.push(el.primary.widget_document.id);
         })
         return getWidgetBySlice(documentIds);
         break;
      
      case "aircraft": 
         prismicDocument.data.data.data.body1.map((el)=>{
            // check if key features widget slice contains other widgets
            if(el.slice_type === "widget") {
               if (el.items.length > 0) {
                  el.items.map(el => documentIds.push(el.other_widgets.id) )
               }
            };
         })
         return getWidgetBySlice(documentIds);
         break;

      case "press_release":
         return getWidgetFromProperties(prismicDocument, 'properties_-_press_release');
         break;

         case "country":
            return getWidgetFromProperties(prismicDocument, 'properties_country');
            break;
         
         case "inspirational":
            return getWidgetFromProperties(prismicDocument, 'properties_inspirational');
            break;

         case "airfield":
            const widgetSlice = prismicDocument?.data?.data?.data?.body?.filter((el)=> el.slice_type === "widget");

            if(widgetSlice && widgetSlice.length > 0) {
               // airfield preview = check if there is a widget slice to render in the preview
               widgetSlice.map((el)=>{ documentIds.push(el.primary.widget_document.id) })
               return getWidgetBySlice(documentIds);

            } else {
               // airfield preview = else if no widget slice on document, check if there is a widget in airfield proprties file 
               return getWidgetFromProperties(prismicDocument, 'properties_airfield');
            }
            break;

      default:
         break;
   }

};

export default fetchWidgetData;