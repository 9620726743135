import React from 'react';
import PropTypes from 'prop-types'
import Cookies from 'js-cookie';
import Prismic from 'prismic-javascript';
import qs from 'qs';

import PreviewWrapper from 'components/preview/PreviewWrapper';

// 96824 - this file incl preview changes

class Preview extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      type: null,
      hero: null,
      id: null,
      post: null,
      data: null,
      props: null
    }
  }

  componentDidMount() {
    this.preview(this.props);
  }

  componentWillUnmount() {
    sessionStorage.clear();
  }

  preview(props) {
    const apiEndpoint = 'https://privatefly.cdn.prismic.io/api/v2';
    const PREVIEW_EXPIRES = 1 / 48; // 30 minutes
    const params = qs.parse(props.location.search.slice(1));

    Prismic
      .getApi(apiEndpoint)
      .then(api => {

        api.previewSession(params.token, this.linkResolver, '/')
      })
      .then(url => {
        Cookies.set(Prismic.previewCookie, params.token, {
          expires: PREVIEW_EXPIRES
        })
      })
      .catch(err => console.log(err))
  }

  linkResolver = doc => { 
    this.setState({
      type: doc.type,
      hero: doc.data.hero_image,
      id: doc.id,
      post: doc.data.post,
      data: doc
    }, function(){
      return '/preview/'
    })
  }
 
  render() {
    const { type, data } = this.state;

    if (data && data.uid && data.uid.indexOf('lorem') !== -1) {
      return (
        <div>
          This document type is not available for preview,
          please select another document type in prismic.
        </div>
      )
    }

    const previewProps = {
      data: {...this.state},
      preview: true
    }

    if (this.state.id) {
      return (
        <PreviewWrapper 
          previewProps={previewProps}
          type={this.state.type}
          id={this.state.id}
          location={this.props.location}
        />
      )
    } else return null
  }
}

Preview.propTypes = {
  location: PropTypes.object
}

export default Preview;