import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import qs from 'qs';

import RenderMainWrapper from 'components/HOC/RenderMainWrapper';

import PreviewSearchComponent from 'components/search/PreviewSearchComponent';
import SearchResults from 'components/search/SearchResults';
import SearchResultsText from 'components/search/SearchResultsText';

import Button from 'components/buttons/button';

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';
import replaceLocalePhoneNumber from 'utils/functions/replaceLocalePhoneNumber';
import { searchTypesenseDocumentsFromOpenCMS } from 'utils/typeSense'
import createProperties from 'utils/functions/createProperties';

const SearchResultsPage = ({ data, location, preview, results, pageContext }) => {
  const [ openCMSResults, createResults ] = useState([])
  const [ localStorageSearchValue, setLocalStorageSearchValue ] = useState("");
  const [ count, setCount ] = useState(9);

  let sortedArray = [];
  let hitsFilteredByLanguage = [];
  let hitsFilteredByEnglish = [];
  let ctaButton;
  let parsedUrl;

  const language = preview ? data.prismicSiteSearch.lang : pageContext.lang;
  const alternateLanguages = preview ? data.prismicSiteSearch.alternate_languages : pageContext.alternateLanguages;

  const { urls } = createProperties(language);

  const siteSearchData = data.prismicSiteSearch.data;

  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  //Hero Image default banner image
  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;
  
  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;
  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);

  //language codes from header query for SEO component
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);

  useEffect(async () => {
    setLocalStorageSearchValue();
    
    let openCMSSearchQuery = localStorage.getItem("siteSearchQuery")
    let blogSearchQueryString = qs.parse(location.search, { parameterLimit: 1 });
    let blogSearchQueryValue = blogSearchQueryString["?searchQuery"]

    if (blogSearchQueryValue) {
      setLocalStorageSearchValue(blogSearchQueryValue)
    }

    if (openCMSSearchQuery) {
      setLocalStorageSearchValue(openCMSSearchQuery)
    }
  }, [])

  useEffect(async () => {
    const generatedOpenCMSResults = await searchTypesenseDocumentsFromOpenCMS(localStorageSearchValue, urls)

    createResults(generatedOpenCMSResults)
    
  }, [localStorageSearchValue])

  if (!preview) {
    ctaButton = siteSearchData.body ? siteSearchData.body[0].primary : null;

    if (ctaButton) parsedUrl = new URL(ctaButton.cta_button.url)

  } else {
    ctaButton = siteSearchData.body.length > 0 ? siteSearchData.body[0].primary : null;
    if (ctaButton) parsedUrl = new URL(ctaButton.cta_button.url)
  }

  const filterUniversalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);
  const filterTelephoneNumber = replaceLocalePhoneNumber(data.allPrismicUniversalPhoneNumber.edges);
  const telephoneNumber = preview ? data.prismicUniversalPhoneNumber.data.phone_local : filterTelephoneNumber;
  const localeUniversalProps = preview ? data.prismicUniversalProperties : filterUniversalProps.node;
  const loadMoreButton = localeUniversalProps.data.load_more_button;

  if (!preview) {
    let hits;

    if (results && results.hits ) {
      hits = results.hits
    } else if (openCMSResults && openCMSResults.hits ) {
      hits = openCMSResults.hits;
    }

    if (hits && hits.length > 0) {
      if (typeof localStorage !== 'undefined') {
        localStorage.removeItem("siteSearchQuery")
      }
    }

    let finalArray = [];
    
    if (process.env.IS_SWISS_DOMAIN === "true") {
      hitsFilteredByLanguage = hits ? hits.filter(el => el.document.lang === language) : [];
      hitsFilteredByEnglish = hits ? hits.filter(el => el.document.lang === 'en-gb') : [];

      if (
        language === "fr-fr" ||
        language === "en-gb"
      ) {
        finalArray = hitsFilteredByLanguage
      } else {
        finalArray = hitsFilteredByEnglish.concat(hitsFilteredByLanguage)
      }
    } else {
      hitsFilteredByLanguage = hits ? hits.filter(el => el.document.lang === process.env.LANG_CODE) : [];
      hitsFilteredByEnglish = hits ? hits.filter(el => el.document.lang === 'en-gb') : [];
      if (
        process.env.LANG_CODE === "fr-fr" ||
        process.env.LANG_CODE === "en-gb"
      ) {
        finalArray = hitsFilteredByLanguage
      } else {
        finalArray = hitsFilteredByEnglish.concat(hitsFilteredByLanguage)
      }
    }

    const uniqArray = Array.from(new Map(finalArray.map(e => [e.document.path, e])).values());

    uniqArray
      .map(el => {
        if (el.document.lang !== 'en-gb') sortedArray.push(el)
        return el
      })
      .map(el => { if (el.document.lang === 'en-gb') sortedArray.push(el) })
  }

  const noResultsParagraph = !preview ? siteSearchData.no_results_paragraph.raw : siteSearchData.no_results_paragraph;
  const resultsParagraph = !preview ? siteSearchData.results_paragraph.raw : siteSearchData.results_paragraph;
  
  noResultsParagraph.map(el => {
    if (!el.spans) {
      let spans = {spans: [{
        end: 0,
        start: 0,
        type: 'strong'
      }]}

      let copy = Object.assign(el, spans);
    }
  })

  const headData = {
    meta_title: siteSearchData.meta_title,
    meta_description: siteSearchData.meta_description,
    meta_keywords: siteSearchData.meta_keywords,
  }

  return (
    <RenderMainWrapper
      location={location}
      meta={headData}
      slices={siteSearchData.body} 
      alternateLanguages={alternateLanguages}
      language={language}
      languageCodes={languageCodes}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      defaultBannerImage={defaultBannerImage}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      icons={filterUniversalProps}
      footerProps={footerProps}
      sideBarProps={filterUniversalProps}
      flightSearchErrorMessages={errorMessages}
      universalProps={filterUniversalProps}
      headerData={headerData}
      preview={preview}
      component={
        <React.Fragment>
          {preview ?
            <PreviewSearchComponent
              siteSearchData={siteSearchData}
              resultsParagraph={resultsParagraph}
              ctaButton={ctaButton}
              parsedUrl={parsedUrl}
              language={language} 
              noResultsParagraph={noResultsParagraph}
            />
          : null}

          {!preview ? 
            <React.Fragment>
              <SearchResultsText 
                siteSearchData={siteSearchData}
                resultsParagraph={resultsParagraph}
                ctaButton={ctaButton}
                parsedUrl={parsedUrl}
                language={language} 
                noResultsParagraph={noResultsParagraph}
                sortedArray={sortedArray}
              />
              <SearchResults 
                sortedArray={sortedArray}
                count={count}
                language={language}
                telephoneNumber={telephoneNumber}
              />
              {count < sortedArray.length ?
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                  <Button 
                    fontColor="white"
                    marginBottom='0'
                    padding='13px 40px'
                    fontSize='14px'
                    fontWeight='500'
                    text={loadMoreButton}
                    onClick={() => setCount(count + 9)}
                  />
                </div>
              : null }
            </React.Fragment>
          : null }
        </React.Fragment>
      }
    />
  )
}

SearchResultsPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool,
  results: PropTypes.array
}

const mapStateToProps = state => ({
  results: state.searchResultsReducer.results
});

export default connect(mapStateToProps, null)(SearchResultsPage)

export const query = graphql`
  query SiteSearchQuery($id: String!) {
    prismicSiteSearch(id: { eq: $id }) {
      ...PrismicSiteSearch
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
  }
`
