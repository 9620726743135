import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import RenderPostWrapper from 'components/HOC/RenderPostWrapper';
import RichPost from 'components/richText';

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const ErrorPage = ({ data, preview, location, pageContext }) => {

  const content = data.prismicErrorPageIndex
  const heroImage = content.data.hero_image;
  const alternateLanguages = preview ? content.data.alternate_languages : pageContext.alternateLanguages;
  const language = preview ? content.data.lang : pageContext.lang;

  let richContent = preview ? content.post : content.data.post.raw

  const headData = {
    meta_title: content.data.meta_title,
    meta_description: content.data.meta_description,
    meta_keywords: content.data.meta_keywords,
  }

  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;

  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);

  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;
  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const universalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);

  //language codes from header query for SEO component
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);

  return (
    <RenderPostWrapper 
      heroImage={heroImage}
      preview={preview}
      meta={headData}
      location={location}
      content={content}
      alternateLanguages={alternateLanguages}
      language={language}
      languageCodes={languageCodes}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      defaultBannerImage={defaultBannerImage}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      icons={universalProps}
      footerProps={footerProps}
      sideBarProps={universalProps}
      flightSearchErrorMessages={errorMessages}
      universalProps={universalProps}
      headerData={headerData}
      component={
        <RichPost 
          richContent={richContent}
          preview={preview}
          language={language}
        />
      }
    />
  );
};

export default ErrorPage;

export const query = graphql`
  query errorQuery($id: String!) {
    prismicErrorPageIndex(id: { eq: $id }) {
      ...PrismicErrorPageIndex
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
  }
`;

ErrorPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
};
