import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import RenderPressAndMediaHomeWrapper from 'components/HOC/RenderPressAndMediaHomeWrapper';
import SlicesWrapper from 'components/slices/containers/pressAndMediaIndex';

import { switchLanguage } from 'utils/functions/switchLanguage';
import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const PressAndMediaIndexPage = ({ data, preview, location, pageContext }) => {

  const [ mediaArticlesToShow, showMoreMediaArticles ] = useState(11);
  const [ pressReleasesToShow, showMorePressReleases ] = useState(6);

  const indexPageData = data.prismicIndexPagePressAndMedia.data;
  const slices = indexPageData.body;
  const indexUrl = indexPageData.url;

  const language = preview ? data.prismicIndexPagePressAndMedia.lang : pageContext.lang;
  const alternateLanguages = preview ? data.prismicIndexPagePressAndMedia.alternate_languages : pageContext.alternateLanguages;
  const localisedPressReleaseDocuments = switchLanguage(data.allPrismicPressRelease.edges, language);
  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;
  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const sideBarProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);
  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;

  const banner = slices.filter((el) => el.slice_type === 'banner_image');
  const anchorNavMenu = slices.filter((el) => el.slice_type === 'page_content_navigation');

    // press release properties for sidebar widget
  const pressReleaseProperties = filterPrismicDataByLanguage(data.allPrismicPropertiesPressRelease.edges, language);
  
  const hasWidget = preview ? data?.allPrismicWidgetData?.edges?.length > 0 
    : pressReleaseProperties?.node?.data?.body?.length > 0;

  const widgetData = preview ? data?.allPrismicWidgetData?.edges : pressReleaseProperties?.node?.data?.body;

  let headData = {
    meta_title: indexPageData.meta_title,
    meta_description: indexPageData.meta_description,
    meta_keywords: indexPageData.meta_keywords,
  }

  const handleMediaPagination = () => {
    showMoreMediaArticles(prevState => prevState + 11)
  }

  const handlePressPagination = () => {
    showMorePressReleases(prevState => prevState + 6)
  }

   // add open graph meta data if available
  if(indexPageData?.body2?.length > 0) {
    const filteredOpenGraphData = indexPageData.body2.filter(el => el.slice_type === "open_graph1");

    if (filteredOpenGraphData?.length > 0) {
      const openGraphData = filteredOpenGraphData[0]?.primary;

      if (openGraphData?.og_title) {
        headData = {
          ...headData,
          og_title: openGraphData.og_title,
          og_description: openGraphData?.og_description || openGraphData?.og_title,
          og_image: openGraphData?.og_image?.url.split('?')[0] || defaultBannerImage.url.split('?')[0],
          og_type: openGraphData?.og_type || 'website',
        }
      }
    }
  }

  return (
    <RenderPressAndMediaHomeWrapper
      meta={headData}
      location={location}
      language={language}
      preview={preview}
      alternateLanguages={alternateLanguages}
      languageCodes={languageCodes}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      icons={data.allPrismicUniversalProperties.edges}
      footerProps={footerProps}
      universalProps={data.allPrismicUniversalProperties.edges}
      headerData={headerData}
      banner={banner.length > 0 && banner[0]}
      anchorNavigation={anchorNavMenu.length > 0 && anchorNavMenu[0]}
      sideBarProps={sideBarProps}
      widget={hasWidget ? widgetData : []}
      fsErrorMessages={errorMessages}
      component={
        <SlicesWrapper
          slices={slices}
          preview={preview}
          mediaArticlesToShow={mediaArticlesToShow}
          handleMediaPagination={handleMediaPagination}
          localisedPressReleaseDocuments={localisedPressReleaseDocuments}
          mainUrl={indexUrl}
          language={language}
          pressReleasesToShow={pressReleasesToShow}
          handlePressPagination={handlePressPagination}
        />
      }
    />
  )
}

export default PressAndMediaIndexPage

PressAndMediaIndexPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
}

export const query = graphql`
  query PressAndMediaIndexPageQuery($id: String!) {
    prismicIndexPagePressAndMedia(id: { eq: $id }) {
      ...PrismicIndexPagePressAndMedia 
    }
    allPrismicPressRelease {
      edges {
        node {
          lang
          data {
            url
            document_title {
              text
            }
            date_of_publication
          }
        }
      }
    }
    allPrismicPropertiesPressRelease {
      edges {
        node {
          ...PrismicPropertiesPressRelease
        }
      }
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
  }
`