import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { H2, H3 } from 'styled';

import ArrowButton from 'components/buttons/ArrowButton';

import { StyledMediaArticlesWrapper, StyledPressAndMediaOverviewWrapper } from 'components/slices/styled';

import RenderRichText from 'utils/richText/renderRichText';

const LoadablePressReleases = loadable(() => import( /* webpackChunkName: "gatsby-PressReleasesList" */'components/press-releases/List'));

const SlicesWrapper = ({ 
  slices,
  preview,
  mediaArticlesToShow,
  handleMediaPagination,
  localisedPressReleaseDocuments,
  mainUrl,
  language,
  pressReleasesToShow,
  handlePressPagination
}) => {  
  return (
    slices ? 
      slices.map((slice, index) => {
        const sliceType = slice.slice_type;

        return (
          <React.Fragment key={slice.id}>
            {(() => {
              switch (sliceType) {

                case "overview_text":
                  return (
                    <H2 
                      marginTop="30px"
                      color="#636463"
                      fontSize="21px" 
                      lineHeight="33px" 
                    >
                      {slice.primary.press_and_media_index_overview_text} 
                    </H2>
                  )
                
                case "anchor":
                  return (
                    <span 
                      id={slice.primary.anchor} 
                      style={{ position: 'absolute', marginTop: '-70px' }}
                    />
                  ) 
                  
                case "media_coverage_section":
                  const mediaCoverageHeading = preview ? slice.primary.media_coverage_heading[0].text 
                    : slice.primary.media_coverage_heading.text;

                  const mediaCoverageText = slice?.primary?.media_coverage_text;

                  const list = slice.items;

                  const mediaItems = list.slice(0, mediaArticlesToShow).map( el => {
                    return <RenderRichText richTextArray={preview ? el.media_article_text : el.media_article_text.raw} /> 
                  })
                  
                  return (
                    <section style={{marginTop: '62px'}}>
                      
                      <StyledPressAndMediaOverviewWrapper>
                        <H3
                          fontSize="24px"
                          marginBottom="15px"
                          color="#000000"
                        >
                          {mediaCoverageHeading}
                        </H3>

                        { mediaCoverageText && <p>{mediaCoverageText}</p> }
                      </StyledPressAndMediaOverviewWrapper>

                      <StyledMediaArticlesWrapper> 
                        { mediaItems }

                        { mediaArticlesToShow < list.length ? (
                          <ArrowButton 
                            buttonText={process.env.LANG_CODE=='fr-fr' ? 'VOIR PLUS' : 'LOAD MORE'}
                            buttonHref="link"
                            theme="red"
                            onClick={handleMediaPagination}
                            isLink={false}
                          /> ) : null
                        }
                      </StyledMediaArticlesWrapper>

                    </section>
                  )
                
                case "press_releases_section": 
                  const pressReleaseHeading = preview ? slice.primary.press_releases_heading[0].text
                    : slice.primary.press_releases_heading.text;

                  const pressReleaseText = slice?.primary?.press_releases_text;
                    
                  return (
                    <section style={{marginTop: '94px'}}>
                      <StyledPressAndMediaOverviewWrapper>
                        <H3
                          fontSize="24px"
                          marginBottom="15px"
                          color="#000000"
                        >
                          {pressReleaseHeading}
                        </H3>

                        {pressReleaseText && <p>{pressReleaseText}</p> }
                      </StyledPressAndMediaOverviewWrapper>

                      <LoadablePressReleases 
                        localisedPressReleaseDocuments={localisedPressReleaseDocuments}
                        preview={preview}
                        mainUrl={mainUrl}
                        language={language}
                        pressReleasesToShow={pressReleasesToShow}
                        handlePressPagination={handlePressPagination}
                      />
                    </section>
                  )

                default: 
                return <React.Fragment />
              }
            })()}
          </React.Fragment>
        )

      })
    :null
  )
};

SlicesWrapper.propTypes = {
  handleMediaPagination: PropTypes.func,
  handlePressPagination: PropTypes.func,
  language: PropTypes.string,
  localisedPressReleaseDocuments: PropTypes.array,
  mainUrl: PropTypes.string,
  mediaArticlesToShow: PropTypes.number,
  pressReleasesToShow: PropTypes.number,
  preview: PropTypes.bool,
  slices: PropTypes.array,
};

export default SlicesWrapper;
