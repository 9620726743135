const axios = require('axios');

export const fetchOpeningTimes = async () => {

  const fetchSpecs = () => axios.get('https://fd-gatsby-manifest-files-grzegrzolka.s3-eu-west-1.amazonaws.com/airfield-opening-times.json');
  const openingTimesResponse = await fetchSpecs();

  const res = openingTimesResponse.data.map(el => {
    return ({
      node: {
        airfield_id: el.id,
        times: el.times,
      }
    })

  });

  return res;

};
