import React, { useState, useEffect } from 'react';
import PropTypes, { array } from 'prop-types';
import { graphql } from 'gatsby';

import SlicesWrapper from 'components/slices/containers/inspirationalIndex';

import RenderProductPageWrapper from 'components/HOC/RenderProductPageWrapper';
import HomepageFlightSearch from 'components/flightSearch/container';

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const InspirationalIndexPage = ({ data, preview, location, pageContext }) => {
  const [ cardsToShow, showMoreCards ] = useState(12);

  const language = preview ? data.prismicIndexInspirati.lang : pageContext.lang;
  const isLanguageFR = language === 'fr-fr' || language === 'fr-xx' || language === 'fr-ch'; 
  const alternateLanguages = preview ? data.prismicIndexInspirati.alternate_languages : pageContext.alternateLanguages;

  const array = []

  const prismicData = data.prismicIndexInspirati.data;
  const allInspirational = data.allPrismicInspirational.edges.filter(el => el.node.lang === ( isLanguageFR ? "fr-fr" : "en-gb"));

  const featured = prismicData.body.find(el => el.slice_type === "inspirational_featured_list");
  const slices = prismicData.body;

  const featuredArray = preview ? data.featuredInspirationalIndexCards : featured.items

  featuredArray.map(el => {
    const cardImg = preview ? el.card_image.url : el.featured_link.document[0].data.card_image.url;
    const cardDesc = preview ? el.card_description : el.featured_link.document[0].data.card_description;
    const cardTitle = preview ? el.card_title : el.featured_link.document[0].data.card_title;
    const cardUrl = preview ? `/` : `/${el.featured_link.data.category}/${el.featured_link.data.url}`;
    
    array.push({
      cardImageUrl: cardImg,
      cardDescription: cardDesc,
      cardTitle: cardTitle,
      cardLink:  cardUrl
    })
  })

  allInspirational.map(el => {
    array.push({
      cardImageUrl: el.node.data.card_image.url,
      cardDescription: el.node.data.card_description,
      cardTitle: el.node.data.card_title,
      cardLink:  `/${el.node.data.category}/${el.node.data.url}`
    })
  })

  const uniqArray = Array.from(new Map(array.map(e => [e.cardLink, e])).values());

  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);
  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;

  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const universalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);

  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;

  let headData = {
    meta_title: prismicData.meta_title,
    meta_description: prismicData.meta_description,
    meta_keywords: prismicData.meta_keywords,
  };

  // add open graph meta data if available
  if(prismicData?.body2?.length > 0) {
    const filteredOpenGraphData = prismicData.body2.filter(el => el.slice_type === "open_graph");

    if (filteredOpenGraphData?.length > 0) {
      const openGraphData = filteredOpenGraphData[0]?.primary;

      if (openGraphData?.og_title) {
        headData = {
          ...headData,
          og_title: openGraphData.og_title,
          og_description: openGraphData?.og_description || openGraphData?.og_title,
          og_image: openGraphData?.og_image?.url.split('?')[0] || defaultBannerImage.url.split('?')[0],
          og_type: openGraphData?.og_type || 'website',
        }
      }
    }
  } 

  const handleCardsPagination = () => {
    showMoreCards(prevState => {
      return prevState + 12
    })
  }

  return (
    <RenderProductPageWrapper
      alternateLanguages={alternateLanguages}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      footerProps={footerProps}
      headerData={headerData}
      icons={universalProps}
      location={location}
      language={language}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      meta={headData}
      preview={preview}
      universalProps={universalProps}
      languageCodes={languageCodes}
      component={ 
        <SlicesWrapper 
          slices={slices} 
          preview={preview}
          allInspirational={uniqArray}
          cardsToShow={cardsToShow}
          handleCardsPagination={handleCardsPagination}
          language={language}
          flightSearchComponent={
            <HomepageFlightSearch 
              isHomePage={false}
              language={language}
              errorMessages={errorMessages}
              sideBarProps={universalProps}
            />
          }
        />
      }
    />
  )
}

export default InspirationalIndexPage

InspirationalIndexPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
}

export const query = graphql`
  query InspirationalIndexQuery($id: String!) {
    prismicIndexInspirati(id: { eq: $id }) {
      data {
          url
          meta_title
          meta_description
          meta_keywords
          body2 {
            ... on PrismicIndexInspiratiBody2OpenGraph {
              slice_type
              primary {
                og_title
                og_description
                og_type
                og_image {
                  dimensions {
                    height
                    width
                  }
                  url
                }
              }
            }
          }
          body {
            ... on PrismicIndexInspiratiBodyBannerImage {
              id
              prismicId
              slice_type
              primary {
                banner_image_size
                display_banner_at_the_bottom
                display_heading1_as_paragraph
                heading1 {
                  raw {
                    text
                    type
                  }
                }
                text_position
                image {
                  url
                  mobile {
                    url
                  }
                }
              }
            }
            ... on PrismicIndexInspiratiBodyFlightSearch {
              id
              slice_type
              primary {
                flight_search {
                  id
                }
              }
            }
            ... on PrismicIndexInspiratiBodyRichtext {
              slice_type
              primary {
                text {
                  raw {
                    text
                    type
                  }
                }
              }
            }
            ... on PrismicIndexInspiratiBodyInspirationalFeaturedList {
              id
              slice_type
              items {
                featured_link {
                  data {
                    url
                    category
                  }
                  document {
                    uid
                    data {
                      card_image {
                        url
                      }
                      card_description
                      card_title
                    }
                  }
                }
              }
            }
            ... on PrismicIndexInspiratiBodyInspirationalMainFeaturedArticle {
              id
              slice_type
              primary {
                card_image {
                  url
                }
                card_text {
                  raw {
                    text
                    type
                  }
                }
                card_link {
                  url
                  data {
                    category
                    url
                  }
                }
                image_position
              }
            }
          }
        }
      }
      allPrismicFooterV2 {
        edges {
          node {
            ...FooterData
          }
        }
      }
      allPrismicHeadermenuV2 {
        edges {
          node {
            ...HeaderData
          }
        }
      }
      prismicUniversalBannerImage {
        data {
          banner_image {
            url
          }
        }
      }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }

    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allPrismicInspirational {
      edges {
        node {
          uid
          lang
          data {
            card_image {
              url
              alt
            }
            card_title
            card_description
            url
            category
          }
        }
      }
    }
    }
`
