import Prismic from 'prismic-javascript'

export function getFeatured(featured, apiEndpoint, self){
    const docs = [];

    featured.map(doc => {
      docs.push(doc.featured_aircraft.id)
    })
    Prismic.api(apiEndpoint).then(api => {
      api.getByIDs(docs)
      .then(res => {
        self.setState({
          featured: res.results
        })
      })
    })
}