const fetchDestinationsDocuments = (prismicDocument) => {
  if (prismicDocument.data.type === "country_listing") {
    let documentIds = [];
    let topDestinations = [];
    let topCountries = [];

    prismicDocument.data.data.data.body1.map(slice => {
      slice.items.map(item => {
        if (slice.slice_type === "featured") documentIds.push(item.featured_destination_link1.id);
        if (slice.slice_type === "top_airfields") documentIds.push(item.airfield_content_relationship.id);
        if (slice.slice_type === "top_destinations") topDestinations.push(item.featured_destination.id);
        if (slice.slice_type === "top_countries") topCountries.push(item.country_relationship.id);
      })
    });
  }
};

export default fetchDestinationsDocuments;
