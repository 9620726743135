import Prismic from "prismic-javascript";

const getLinkedAircraft = (apiEndpoint, category) => {
  let docs = [];
  let allLinkedAircraft = [];

  category.map(doc => {
    docs.push(doc.category.id);
  });

  return Prismic.api(apiEndpoint)
    .then(api => {
      return api.getByIDs(docs)
        .then(res => {
          let aircraft = [];

          res.results.map(doc => {
            return doc.data.body1[0].items.map(el => {
              aircraft.push(el.featured_aircraft.id)
              return el.featured_aircraft.id;
            });
          });

          return aircraft
        })
        .then(aircraft => {
          return api.getByIDs(aircraft, { pageSize : 100 }).then(res => {
            allLinkedAircraft.push(...res.results);
            return res.results
          });
        })
    });
};

export default getLinkedAircraft;
