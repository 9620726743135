//function that center a given string in rich text, this wraps the string in <strong> tag and then applies custom classes to center it
export function centerParagraph (richContent, string1, string2) {  
  if (richContent) {
    
    let text = richContent
      .map(el => {
        if (el.type === "paragraph") {
          if (el.text !== null) {
            let target1 = el.text.indexOf(string1);
            let target2 = el.text.indexOf(string2);

            if (target1 >= 0) {
              el.spans.push({
                end: target1 + string1.length,
                start: target1,
                type: "strong",
                label: "rich-text-centered-string"
              });
            }

            if (target2 >= 0) {
              el.spans.push({
                end: target2 + string2.length,
                start: target2,
                type: "strong",
                label: "rich-text-centered-string"
              });
            }
          }
        }
      })
    
    return richContent
  }
}