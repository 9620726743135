import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import Layout from "components/layout";
import HomepageFlightSearch from 'components/flightSearch/container';
import Banner from 'components/banner';

import SEO from "SEO";

import { FullWidthContainer } from 'components/grid/styled';

import { setPhoneNumber } from 'state/actions/phoneNumber';

const RenderMainWrapper = props => {
  useEffect(() => {
    props.setPhoneNumber(props.phoneNumber)
  }, [])

  return (
    <Layout 
      location={props.location} 
      language={props.language}
      alternateLanguages={props.alternateLanguages}
      localePhoneNumber={props.localePhoneNumber}
      universalProps={props.universalProps}
      errorMessages={props.errorMessages}
      icons={props.icons}
      footerProps={props.footerProps}
      headerData={props.headerData}
      preview={props.preview}
      uid={props.uid}
      previewHeaderData={props.headerData}
      previewFooterData={props.footerProps.node}
    >
      <SEO 
        meta={props.meta} 
        location={props.location}
        language={props.language}
        alternateLanguages={props.preview ? [] : props.alternateLanguages}
        languageCodes={props.languageCodes}
        preview={props.preview}
        phoneNumber={props.phoneNumber}
      />
      <Banner 
        desktopLarge={false}
        desktopMedium={true}
        desktopSmall={false}
        isHomePage={false}
        // headingOne={headingOneText && <h1>{headingOneText}</h1>}
        isTemplate={true}
        bannerImageSrc={props.heroImage && props.heroImage.url ? props.heroImage.url : props.defaultBannerImage.url}
      />
      <FullWidthContainer>
        {props.component}
        <HomepageFlightSearch 
          language={props.language}
          errorMessages={props.flightSearchErrorMessages}
          sideBarProps={props.sideBarProps}
        />
      </FullWidthContainer>
    </Layout>
  );
};

const mapDispatchToProps = dispatch => ({
  setPhoneNumber: phoneNumber => dispatch(setPhoneNumber(phoneNumber))
})

export default connect(null, mapDispatchToProps)(RenderMainWrapper);

RenderMainWrapper.propTypes = {
  alternateLanguages: PropTypes.array,
  component: PropTypes.object,
  defaultBannerImage: PropTypes.object,
  errorMessages: PropTypes.array,
  flightSearchErrorMessages: PropTypes.object,
  // flightSearchIcons: PropTypes.object,
  // flightSearchProps: PropTypes.object,
  footerProps: PropTypes.object,
  headerData: PropTypes.object,
  heroImage: PropTypes.object,
  icons: PropTypes.array,
  language: PropTypes.string,
  languageCodes: PropTypes.array,
  localePhoneNumber: PropTypes.array,
  location: PropTypes.object,
  // mapIcons: PropTypes.object,
  meta: PropTypes.object,
  phoneNumber: PropTypes.string,
  preview: PropTypes.bool,
  setPhoneNumber: PropTypes.func,
  sideBarProps: PropTypes.object,
  uid: PropTypes.string,
  universalProps: PropTypes.array,
};
