import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import Banner from 'components/banner';
import RenderRichText from 'utils/richText/renderRichText';
import Card from 'components/card';
import ArrowButton from 'components/buttons/ArrowButton';
import NewSlider from 'components/carousel/newslider';

import { FullWidthContainer, FullWidthSmallCardsContainer } from 'components/grid/styled';

import { StyledInspirationalSlicesWrapper, StyledDestinationTextWrapper, StyledAllCountriesButtonMobile } from 'components/slices/styled';

const SlicesWrapper = ({ slices, preview, language, uid, flightSearchComponent, allInspirational, cardsToShow, handleCardsPagination }) => {  
  return (
    <StyledInspirationalSlicesWrapper>
      {slices ? 
        slices.map((slice, index) => {
          const sliceType = slice.slice_type;
          
          return (
            <React.Fragment key={slice.id}>
              {(() => {
                switch (sliceType) {
                  case "banner_image": 

                    const bannerImageSrc = slice.primary.image.url;
                    const textPosition = slice.primary.text_position;
                    
                    const headingOneRichText = slice.primary.heading1.length > 0 && preview ? slice.primary.heading1 : slice.primary.heading1.raw;
                    
                    const headingTwoRichText = slice.primary.heading2 ? slice.primary.heading2.length > 0 && preview ? slice.primary.heading2 : slice.primary.heading2.raw : [];
                    
                    const headingThreeRichText = slice.primary.heading3 ? slice.primary.heading3.length > 0 && preview ? slice.primary.heading3 : slice.primary.heading3.raw: [];
                    
                    const paragraphRichText = slice.primary.banner_text ? slice.primary.banner_text.length > 0 && preview ? slice.primary.banner_text[0].raw : slice.primary.banner_text.raw : [];

                    const isBottomBanner = slice.primary.display_banner_at_the_bottom;
                    const bannerImageSize = slice.primary.banner_image_size;
                  
                    return (
                      <Banner 
                        desktopLarge={bannerImageSize === "large" ? true: false}
                        desktopMedium={bannerImageSize === "medium" ? true: false}
                        desktopSmall={bannerImageSize === "small" ? true: false}
                        isHomePage={false}
                        headingOne={headingOneRichText && <RenderRichText richTextArray={headingOneRichText} />}
                        textPosition={textPosition}
                        bannerImageSrc={bannerImageSrc}
                        text={paragraphRichText && <RenderRichText richTextArray={paragraphRichText} />}
                        isBottomBanner={false}
                        buttonComponent={null}
                      />
                  )

                  case "destination_list":
                    const richTextArray = preview ? slice.primary.section_title : slice.primary.section_title.raw;
                    const list = slice.items;

                    const check = list.length % 4 == 0;
                    const b = Math.ceil(list.length / 4) * 4
                  
                    if (check === false) {
                      const c = b - list.length;
                  
                      const x = [...Array(c).keys()]
                  
                      x.map(el => {
                          list.push({
                      id: "empty"
                    })
                      })
                    }

                    return (
                      <React.Fragment>
                        <FullWidthContainer isHomePage={false}>
                          <StyledDestinationTextWrapper>
                            <RenderRichText 
                              richTextArray={richTextArray} 
                            />
                          </StyledDestinationTextWrapper>
                        </FullWidthContainer>
                        <FullWidthSmallCardsContainer isCarousel={false}>
                          {list.slice(0, cardsToShow).map(el => {

                            const cardImageUrl = el?.card_image?.url;
                            const cardDescription = preview ? el?.card_description : el?.card_description?.raw
                            const cardTitle = preview ? el?.card_title?.length > 0 && el.card_title[0].text : el?.card_title?.text;
                            const cardLink = el?.card_link?.url;

                            if (el.id !== "empty") {
                              return (
                                <Card 
                                  headingText={ 
                                    <h4 style={{textAlign: "left", marginBottom: "10px", marginTop: "10px", fontSize: "18px" }}>
                                      {cardTitle}
                                    </h4>
                                  }
                                  cardText={cardDescription && <RenderRichText richTextArray={cardDescription} />}
                                  sizeSmall={true}
                                  cardImageUrl={cardImageUrl}
                                  hasSeperator={false} 
                                  desktopOneRowCard={false} 
                                  isCardLink={true}
                                  cardLink={cardLink}
                                  textAlign="left"
                                  language={language}
                                />
                              )
                            } else {
                              return (
                                <Card 
                                  sizeSmall={true}
                                  hasSeperator={false} 
                                  desktopOneRowCard={false} 
                                  isCardLink={true}
                                  className="aircraft-card"
                                  hideImageInMobile={false}
                                  isFakeCard={true}
                                />
                              )
                              
                            }

                          })}
                          {
                          cardsToShow < list.length ?
                            (
                              <div style={{width: "100%", display: "flex", justifyContent: "flex-start", marginTop: "-20px"}}>
                                <ArrowButton 
                                  isLink={false}
                                  theme="red"
                                  buttonText={process.env.LANG_CODE=='fr-fr' || language === 'fr-fr' ? 'VOIR PLUS' : 'LOAD MORE'}
                                  onClick={handleCardsPagination}
                                  padding="20px"
                                />
                              </div>
                            )
                          : null
                        }
                        </FullWidthSmallCardsContainer>
                        <NewSlider 
                          length={list}
                          minHeight="350px"
                          category={"destinations-cards"}
                          component={
                            <React.Fragment>
                              {list.map(el => {
                            
                                const cardImageUrl = el.card_image && el.card_image.url;
                                const cardDescription = preview ? el.card_description : el.card_description && el.card_description.raw
                                const cardTitle = el.card_title && el.card_title.text;
                                const cardLink = el.card_link && el.card_link.url;

                                    if (el.id !== "empty") {
                                      return (
                                        <Card 
                                          headingText={ <h4 
                                            style={{
                                              textAlign: "left", 
                                              marginBottom: "10px", 
                                              marginTop: "10px", 
                                              fontSize: "18px"
                                            }}
                                                        >
                                            {cardTitle}
                                          </h4>}
                                          cardText={cardDescription && <RenderRichText richTextArray={cardDescription} />}
                                          sizeSmall={true}
                                          cardImageUrl={cardImageUrl}
                                          hasSeperator={false}
                                          desktopOneRowCard={false}
                                          isCardLink={true}
                                          language={language}
                                          cardLink={cardLink}
                                          textAlign="left"
                                          isCarousel={true} 
                                          className="carousel-card"
                                        />
                                      )
                                    } else {
                                      return (
                                        <Card 
                                          sizeSmall={true}
                                          hasSeperator={false}
                                          desktopOneRowCard={false}
                                          isCardLink={true}
                                          isCarousel={true} 
                                          className="carousel-card"
                                          hideImageInMobile={false}
                                          isFakeCard={true}
                                        />
                                      )
                                    }
                                })}

                            </React.Fragment>
                              }
                        /> 
                       
                      </React.Fragment>
                  )

                  case "country_list":
                    const countrySectionichTextArray = preview ? slice.primary.section_title : slice.primary.section_title.raw;
                    const countryList = slice.items;

                    const isFrench = process.env.LANG_CODE === 'fr-fr' || language === 'fr-fr';

                    return (
                      <React.Fragment>
                        <FullWidthContainer>
                          <StyledDestinationTextWrapper>
                            <RenderRichText 
                              richTextArray={countrySectionichTextArray} 
                            />
                          </StyledDestinationTextWrapper>
                        </FullWidthContainer>
                        <FullWidthSmallCardsContainer isCarousel={false}>
                          {countryList.slice(0, cardsToShow).map(el => {
                            const cardImageUrl = el?.card_image?.url;
                            const cardTitle = preview ? el.card_title[0]?.text : el?.card_title?.text;
                            const cardLink = el?.card_link?.url;

                            if (el.id !== "empty") {
                              return (
                                <Card 
                                  headingText={ <h4 
                                    style={{
                                      textAlign: "left", 
                                      marginBottom: "10px", 
                                      marginTop: "10px", 
                                      fontSize: "18px"
                                    }}
                                                >
                                    {cardTitle}
                                  </h4>}
                                  sizeSmall={true}
                                  cardImageUrl={cardImageUrl}
                                  hasSeperator={false}
                                  desktopOneRowCard={false}
                                  isCardLink={true}
                                  language={language}
                                  cardLink={cardLink}
                                  textAlign="left"
                                />
                              )
                            } else {
                              return (
                                <Card 
                                  sizeSmall={true}
                                  hasSeperator={false}
                                  desktopOneRowCard={false}
                                  isCardLink={true}
                                  className="carousel-card"
                                  hideImageInMobile={false}
                                  isFakeCard={true}
                                />
                              )
                              
                            }

                          })}
                          <div style={{width: "100%", display: "flex", justifyContent: "flex-start", marginTop: "-20px"}}>
                            <ArrowButton 
                              isLink={true}
                              theme="red"
                              buttonText={isFrench ? "TOUS LES PAYS" : "all top countries"}
                              onClick={handleCardsPagination}
                              padding="20px"
                              buttonHref={isFrench ? '/vol-prive/countries' : '/private-jet-hire/countries'} 
                              language={language}
                            />
                          </div>
                        </FullWidthSmallCardsContainer>
                        <React.Fragment>
                          <NewSlider 
                            length={countryList}
                            minHeight="250px"
                            category={"country-cards"}
                            component={
                              <React.Fragment>
                                {countryList.slice(0, cardsToShow).map(el => {
                                  const cardImageUrl = el.card_image.url;
                                  const cardTitle = el.card_title.text;
                                  const cardLink = el.card_link.url;

                                  if (el.id !== "empty") {
                                    return (
                                      <Card 
                                        headingText={ 
                                          <h4 style={{ textAlign: "left", marginBottom: "10px", marginTop: "10px", fontSize: "18px"}}>
                                            {cardTitle}
                                          </h4>
                                        }
                                        sizeSmall={true}
                                        cardImageUrl={cardImageUrl}
                                        hasSeperator={false}
                                        desktopOneRowCard={false}
                                        isCardLink={true}
                                        cardLink={cardLink}
                                        language={language}
                                        textAlign="left"
                                        isCarousel={true} 
                                        className="carousel-card"
                                      />
                                    )
                                  } else {
                                    return (
                                      <Card 
                                        sizeSmall={true}
                                        hasSeperator={false}
                                        desktopOneRowCard={false}
                                        isCardLink={true}
                                        hideImageInMobile={false}
                                        isFakeCard={true}
                                        isCarousel={true} 
                                        className="carousel-card"
                                      />
                                    )
                                  }

                                })}
                              </React.Fragment>
                            }
                          />
                          <StyledAllCountriesButtonMobile>
                            <ArrowButton 
                              isLink={true}
                              language={language}
                              theme="red"
                              buttonText={isFrench ? "TOUS LES PAYS" : "all top countries"}
                              onClick={handleCardsPagination}
                              padding="20px"
                              buttonHref={`${isFrench ? '/vol-prive/countries' : '/private-jet-hire/countries' }`} 
                            />
                          </StyledAllCountriesButtonMobile>
                          <div style={{marginBottom: "40px"}} /> 
                        </React.Fragment>
                      </React.Fragment>
                    )

                  // TODO test and add 
                  // case "anchor":
                  // case "anchors":
                  //   return <a id={slice.primary.anchor} /> 

                  case "flight_search": 
                    return (
                      <Media query="(min-width: 1200px)">
                        {matches =>
                          matches ? (
                            <FullWidthContainer>
                              {flightSearchComponent}
                            </FullWidthContainer>
                          ) : flightSearchComponent
                        }
                      </Media>
                      
                  )

                  case 'richtext': 
                    const mainRichText = preview ? slice.primary.text : slice.primary.text.raw;

                    return (
                      <FullWidthContainer>
                        <StyledDestinationTextWrapper>
                          <RenderRichText 
                            richTextArray={mainRichText}
                          />
                        </StyledDestinationTextWrapper>
                      </FullWidthContainer>
                    )
                  
                  default: 
                    return <React.Fragment />
                }
              })()}
            </React.Fragment>
          )
        })
      : null}
    </StyledInspirationalSlicesWrapper>
  )
};

SlicesWrapper.propTypes = {
  allInspirational: PropTypes.array,
  cardsToShow: PropTypes.number,
  flightSearchComponent: PropTypes.object,
  handleCardsPagination: PropTypes.func,
  language: PropTypes.string,
  preview: PropTypes.bool,
  slices: PropTypes.array,
  uid: PropTypes.string,
};

export default SlicesWrapper;
