import React from 'react';
import PropTypes from 'prop-types';

import InternalLink from 'components/links/internalLink';

import { H3, StyledSearchResultsWrapper } from 'styled';

const regex = /\b(?:privatefly \| |private jet charter \||hire \| |air | \| privatefly)|(\|)/gi
const regexTelephoneNumber = /{{telephone}}/g;

function SearchResults({ sortedArray, language, telephoneNumber, count }) {
  return (
    <StyledSearchResultsWrapper>
      {
        sortedArray.slice(0, count).map(el => {
          let path;
          const title = el.document.title ? el.document.title.replace(regex, " ") : " ";

          if (el.document.path.startsWith("/")) {
            path = el.document.path
              .slice(1)
              .replace("fr/", "")
          } else {
            path = el.document.path
            .replace("fr/", "")
          }

          return (
            <div>
              <InternalLink 
                path={`/${path}`} 
                language={language}
                style={{color: "rgba(0, 0, 0, 0.65)", fontWeight: 400}}
                linkComponent={
                  <React.Fragment>
                    <H3 
                      fontWeight='400' 
                      fontSize='22px'
                      fontColor='#e30613'
                      style={{
                        whiteSpace: 'nowrap',
                        wordWrap: 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingBottom: '5px'
                      }}
                    >
                      {title}
                    </H3>
                    <div className='searchResults-highlight'>
                      {el.highlights.map(el => {
                        if (el.snippets) {
                          return el.snippets.slice(0, 2).map(snippet => {
                            let newSnippet = snippet.replace(regexTelephoneNumber, telephoneNumber);

                            return (
                              <span style={{marginRight: "5px" }} dangerouslySetInnerHTML={{__html: newSnippet }} />
                            )
                          })
                        } else {
                          let newSnippet = el.snippet.replace(regexTelephoneNumber, telephoneNumber);

                          return (
                            <span style={{marginRight: "5px" }} dangerouslySetInnerHTML={{__html: newSnippet }} />
                          )
                        }
                      })}
                    </div>
                  </React.Fragment>
                }
              />
            </div>
          )
        })
      }
    </StyledSearchResultsWrapper>
  )
}

SearchResults.propTypes = {
  count: PropTypes.number,
  language: PropTypes.string,
  sortedArray: PropTypes.array,
  telephoneNumber: PropTypes.string,
}

export default SearchResults

