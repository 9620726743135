import Prismic from "prismic-javascript";

const getLinkedCategory = (category, apiEndpoint) => {
  let docs = [];

  category.map(doc => {
    docs.push(doc.category.id);
  });

  return Prismic.api(apiEndpoint).then(api => {
    return api.getByIDs(docs).then(res => {
      return res.results
    });
  });
};

export default getLinkedCategory;
