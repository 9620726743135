import React, { Component } from 'react';
import PropTypes from "prop-types";
import Prismic from 'prismic-javascript';
import axios from 'axios';

import AircraftListing from 'templates/AircraftListing';
import { getAllAircraft } from 'components/preview/functions/GetAllAircraft';
import { getFeatured } from 'components/preview/aircraftCat/functions/getFeatured';

class AircraftListingPreview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      response: this.props.data,
      specs: null,
      aircraftProps: null,
      aircraftHome: null,
      allAircraft: null,
      featured: null
    }
  }

  componentDidMount(){
    const apiEndpoint = 'https://PrivateFly.prismic.io/api/v2';
    const self = this;

    Promise
      .all([
        axios.get(process.env.GATSBY_AIRCRAFT_SPECS_URL),
        Prismic.api(apiEndpoint).then(api => {
          return api.getSingle("aircraftproperties", { lang: "*" });
        }),
        Prismic.api(apiEndpoint).then(api => {
          return api.getSingle("aircraft_home", { lang: "*" });
        })
      ])
      .then(results => {
        this.setState({
          specs: results[0],
          aircraftProps: results[1],
          aircraftHome: results[2]
        })
      });

      getAllAircraft(self)
      const featured = this.state.response.data.body1[0].items

      getFeatured(featured, apiEndpoint, self)
  }

  render(){
      const { response, specs, aircraftProps, aircraftHome, allAircraft, featured } = this.state
      const { 
        prismicUniversalProperties,
        allPrismicUniversalProperties, 
        allPrismicPropertiesErrorMessages,
        allPrismicFlightsearchproperties,
        allPrismicAircraftHome,
        allPrismicAircraftproperties
       } = this.props;

      if (response && allAircraft && specs && featured && aircraftHome && aircraftProps){
       
      return (
        <AircraftListing
          data={{
            ...this.props,
            prismicAircraftListing: response,
            prismicAircraftproperties: {node: aircraftProps },
            allAircraftSpecs: specs,
            allPrismicAircraft: {edges: allAircraft.map(el => { return {node: el} })},
            prismicAircraftHome: aircraftHome,
            featured: featured,
            prismicUniversalProperties: prismicUniversalProperties,
            allPrismicUniversalProperties: allPrismicUniversalProperties,
            allPrismicPropertiesErrorMessages: allPrismicPropertiesErrorMessages,
            allPrismicFlightsearchproperties: allPrismicFlightsearchproperties,
            allPrismicAircraftHome: allPrismicAircraftHome,
            allPrismicAircraftproperties: allPrismicAircraftproperties,
            
        }} 
          location={this.props.location}
          preview={true}
        />
      )
     } else return <React.Fragment />
    } 
}

AircraftListingPreview.propTypes = {
  allPrismicAircraftHome: PropTypes.array,
  allPrismicAircraftproperties: PropTypes.array,
  allPrismicFlightsearchproperties: PropTypes.array,
  allPrismicPropertiesErrorMessages: PropTypes.array,
  allPrismicUniversalProperties: PropTypes.array,
  data: PropTypes.object,
  location: PropTypes.object,
  prismicUniversalProperties: PropTypes.object
}

export default (AircraftListingPreview)