import Prismic from "prismic-javascript";

import createProperties from 'utils/functions/createProperties';

// 96824 - this file incl preview changes

const fetchProductFeaturedAircraft = (prismicDocument) => {
   const { prismicEndpoint } = createProperties();
   let featuredAircraftData = [];

   if (prismicDocument.data.type === "product") {
      let documentIds = [];

      prismicDocument.data.data.data.body.map((el)=>{
         if(el.slice_type === "featured_aircraft_section") {
            el.items.map((el)=>{
               documentIds.push(el.featued_aircraft.id);
            })
         }
      })

      return Prismic
      .api(prismicEndpoint)
      .then(api => api.getByIDs(documentIds))
      .then(res => {
         res.results.map((el) => featuredAircraftData.push(el.data) )

         return featuredAircraftData
      })

   }

};

export default fetchProductFeaturedAircraft;