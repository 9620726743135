import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import InternalLink from 'components/links/internalLink';

import RenderPostWrapper from 'components/HOC/RenderPostWrapper';

import { StyledSiteMapList, StyledSiteMapButton, StyledNestedSiteMapList } from 'styled';
import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';
class SiteMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: null,
      close: false
    }
  }

  openAccordion = (index) => {
    this.setState((prevState, props) => {
      if (prevState.count === index) {
        this.setState({ close: !this.state.close, count: null })
      }

      if (prevState.count !== index) {
        this.setState({ close: false, count: null })
      }

      this.setState({ count: index })
    })
  }

  render() {
    const { data, preview, location, pageContext } = this.props;
    const language = preview ? data.prismicSiteMap.lang : pageContext.lang;
    const alternateLanguages = preview ? data.prismicSiteMap.alternate_languages : pageContext.alternateLanguages;
    const universalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);
    const { node: {data: { close_icon } }} = universalProps;

    const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
    //Hero Image default banner image
    const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
    const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;
    
    const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;
    const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
    const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);

    //language codes from header query for SEO component
    const languageCodes = footerProps.node.data.language.map(el => el.country_code);

    // find list on level 2 and add it to a previous list with level 1 as a nested list
    data.prismicSiteMap.data.body.forEach(el => {
      el.items.forEach((item, index, arr) => {
        if (item.dropdown === "level 2") {
          arr[index-1].nested = item.list_items
    
          arr.splice(arr.indexOf(item), 1)
        }
      })
    })

  const headData = {
    meta_title: data.prismicSiteMap.data.meta_title,
    meta_description: data.prismicSiteMap.data.meta_description,
    meta_keywords: data.prismicSiteMap.data.meta_keywords,
  }

  return (
    <RenderPostWrapper 
      preview={preview}
      meta={headData}
      location={location}
      language={language}
      alternateLanguages={alternateLanguages}
      languageCodes={languageCodes}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      defaultBannerImage={defaultBannerImage}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      icons={universalProps}
      footerProps={footerProps}
      sideBarProps={universalProps}
      flightSearchErrorMessages={errorMessages}
      universalProps={universalProps}
      headerData={headerData}
      component={
        <StyledSiteMapList height={this.state.count}>
          {data.prismicSiteMap.data.body.map((el, index) => {

            return (
              <li className="toplevel-sitemap-list-item" onClick={() => this.openAccordion(index)}>
                <StyledSiteMapButton 
                  closeIcon={ preview ? 'https://images.prismic.io/privatefly/87f9e3fe-f8b9-4cab-8fc7-34a5f268216e_x-icon.png' : close_icon.url} 
                />
                {el.items.map(elem => {
                  const topLevel = elem.dropdown === "top level";

                  if (topLevel) {
                    return (
                      <React.Fragment>
                        {preview ? elem.list_items[0].text : elem.list_items.text}

                        <StyledNestedSiteMapList 
                          className="level1-sitemap-list" 
                          height={this.state.count}
                          topIndex={index}
                          close={this.state.close}
                        >
                          {el.items.map(item => {
                              const items = preview ? item.list_items : item.list_items.raw;
                              const firstLevel = item.dropdown === "level 1";

                              if (firstLevel) {

                                return items.map((i, index) => {
                                  let url;

                                  if (i.spans.length > 0) {
                                    url = new URL(i.spans[0].data.url)
                                  }
                                  
                                  if (item.nested && index === items.length -1) {

                                    return (
                                     
                                      <li className="level1-sitemap-list-item">
                                        <InternalLink 
                                          path={`${url.pathname}`} 
                                          language={language}
                                          style={{color: "rgba(0, 0, 0, 0.65)", fontWeight: 400}}
                                          linkText={i.text}
                                        />
                                        <ul>
                                          {items.map(nestedItem => {
                                            if (nestedItem.spans.length > 0) {
                                              let url = new URL(nestedItem.spans[0].data.url);

                                              return (
                                                <InternalLink 
                                                  path={`${url.pathname}`} 
                                                  language={language}
                                                  style={{color: "rgba(0, 0, 0, 0.65)", fontWeight: 400}}
                                                  linkComponent={
                                                    <li className="level2-sitemap-list-item">{nestedItem.text}</li>
                                                  }
                                                />
                                              )
                                            } else 
                                            return <li className="level2-sitemap-list-item">{nestedItem.text}</li>
                                          })}
                                        </ul>
                                      </li>
                                    )
                                  } 

                                  return (
                                    <InternalLink 
                                      path={`${url.pathname}`} 
                                      language={language}
                                      style={{color: "rgba(0, 0, 0, 0.65)", fontWeight: 400}}
                                      linkComponent={
                                        <li className="level1-sitemap-list-item">{i.text}</li>
                                        }
                                    />
                                  )
                                })
                              }
                            })}
                        </StyledNestedSiteMapList>
                      </React.Fragment>
                    )
                  }
                })}
              </li>
            )
          })}
        </StyledSiteMapList>
      }
    />
  )
  }
}

SiteMap.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
}

export default SiteMap

export const query = graphql`
  query SiteMapQuery($id: String!) {
    prismicSiteMap(id: { eq: $id }) {
      ...PrismicSiteMap
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
  }
`
