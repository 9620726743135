import styled from 'styled-components';

export const StyledCountryTableBody = styled.tbody`
  display: flex;
  flex-wrap: wrap;
  background: #EFEFEF;
  padding: 1em 2em 1em 2em;

  * {
   font-weight: 300;
   font-size: 14px;
   color: #1890ff;
  }
`;

export const StyledCountryTableRow = styled.tr`
  width: 100%;
  border-bottom: solid thin #c4c0c6;
  padding: 0.5rem 0 0.5rem 0;

  @media (min-width: 850px) {
    width: 50%;
    
    :nth-last-child {
      border-bottom: none;
    }
  }
`;

export const StyledCountryTableCell = styled.td`
  display: inline-block;
  border: 0;
  font-weight: 600;
  min-width: 46%;

  @media (min-width: 850px) {
    min-width: 180px;
  }
`;