import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby";

import RenderBlogsWrapper from 'components/HOC/RenderBlogsWrapper';
import RichPost from 'components/richText';
import Article from 'components/blog/Articles';

import replaceLocalePhoneNumber from 'utils/functions/replaceLocalePhoneNumber';
import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';
import { switchLanguage } from 'gatsbyNode/utils/switchLanguage';

const BlogArticle = ({ data, preview, location, pageContext}) => {

  const blogData = data.prismicPost.data; 
  const relatedBlogArticles = preview ? [] : switchLanguage(data.allPrismicPost.edges).map(x => x.node);
  const language = preview ? data.prismicPost.lang : pageContext.lang;
  const alternateLanguages = [];
  const slices = blogData?.body1;
  
  // backup for Open Graph images - 91461
  let backup_og_image_array;
  let backup_og_img;

  if(!preview){
    if(blogData.post.raw !== null){
      // As long as the article has data, we can use the first image in the article as the backup OG image
      if (slices !==null && slices.length > 1) {
        backup_og_image_array = slices.filter(el=> el.slice_type === 'richtext')[0].primary.text.raw.filter(el=> el.type==='image');
        
        if (backup_og_image_array.length > 0){
          // find backup OG images from Slices
          backup_og_img = backup_og_image_array[0].url; 
        } 
    
      } else {
        backup_og_image_array = blogData.post.raw.filter(el=> el.type ==='image');
        
        if (backup_og_image_array.length > 0){
          // find backup OG images from Post
          backup_og_img = backup_og_image_array[0].url; 
        }
      }
      
    } else {
      // default banner img
      backup_og_img = 'https://images.prismic.io/privatefly/a8f80e5f-2333-415a-95d3-9dbbbf9d1f0b_Engine-Intake-Rim.png';

    }
  }
  
  const richText = blogData.post.raw 
  const datePublished = blogData.date_published
  
  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges);
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);
  const phoneNumber = replaceLocalePhoneNumber(data.allPrismicUniversalPhoneNumber.edges);
  //   const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges);
  // const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;

  const universalBlogProps = filterPrismicDataByLanguage(data.allPrismicUniversalBlogProperties.edges);
  const { node: {data: { read_more_link_text } }} = universalBlogProps;

  const bannerImageSlice = slices && slices.find(el => {
    if (el.slice_type === "banner_image") return el
  });

  const headData = {
    meta_title: blogData.meta_title,
    meta_description: blogData.meta_description,
    meta_keywords: blogData.meta_keywords,
    og_title: blogData.body2 ? blogData?.body2[0]?.primary?.og_title || blogData.meta_title : blogData.meta_title,
    og_description: blogData.body2 ? blogData?.body2[0]?.primary?.og_description || blogData.meta_description : blogData.meta_description,
    og_image: blogData.body2 ? blogData?.body2[0]?.primary?.og_image?.url.split('?')[0] || backup_og_img : backup_og_img,
    og_date_published: datePublished,
  }

  return (
    <RenderBlogsWrapper
      alternateLanguages={alternateLanguages}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      footerProps={footerProps}
      headerData={headerData}
      icons={data.allPrismicUniversalProperties.edges}
      location={location}
      language={language}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      meta={headData}
      preview={preview}
      universalProps={data.allPrismicUniversalProperties.edges}
      languageCodes={languageCodes}
      component={
        <Article
          universalBlogProps={universalBlogProps} 
          bannerImageSlice={bannerImageSlice}
          preview={preview}
          articleComponent={
            <RichPost 
              richContent={richText}
              slices={slices} 
              preview={preview}
              datePublished={datePublished}
              headData={headData}
              isBlog={true}
              blogAritclePhoneNumber={phoneNumber}
              relatedBlogArticles={relatedBlogArticles}
              readMoreText={read_more_link_text}
              pageContext={pageContext}
            />
          }
        />
      }
    />
  )
}

BlogArticle.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool,
}

export default BlogArticle;

export const query = graphql`
  query BlogArticleQuery($id: String!, $relatedIds: [String]) {
    
    prismicPost(id: { eq: $id }) {
      ...PrismicPost
    }
    allPrismicPost(
      filter: { uid: {nin:["about", "test-lorem"]}, id: {in: $relatedIds} }
      sort: {fields: data___date_published, order: DESC}
      limit: 10
    ) { 
      edges {
        node {
          id
          uid
          lang
          type
          tags
          last_publication_date
          data {
            date_published
            url
            card_title
            card_description
            card_image {
              url
              dimensions{
                width
                height
              }
            }
          }
        }
      }
    }
    allPrismicUniversalBlogProperties {
      edges {
        node {
          lang
          data {
            body {
              id
              prismicId
              slice_type
              primary {
                banner_image_size
                display_banner_at_the_bottom
                display_heading1_as_paragraph
                text_position
                heading1 {
                  raw {
                    text
                    type
                  }
                }
                image {
                  url
                  mobile {
                    url
                  }
                }
              }
            }
            meta_title
            meta_description
            meta_keywords
            read_more_link_text
          }
        }
      }
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
  }
`;