export default function replaceVariablesFromPrismic(
  prismicDocumentData, 
  phoneNumber, 
  airfield, 
  country, 
  cityName, 
  aircraft
) {

  const replaceString = (string) => {
    let newString;

    if (string) {
      newString = string
      .replace(/{{airfield}}/g, airfield)
      .replace(/{{country}}/g, country)
      .replace(/{{cityName}}/g, cityName)
      .replace(/{{telephone}}/g, phoneNumber)
      .replace(/{{aircraft}}/g, aircraft);
    }

    return newString
  }

  const title = replaceString(prismicDocumentData.meta_title)
  const description = replaceString(prismicDocumentData.meta_description)
  const keywords = replaceString(prismicDocumentData.meta_keywords)

  return {
    title,
    description,
    keywords
  }
}