const axios = require('axios');

const fetchAircraftSpecs = async () => {
  const fetchSpecs = () => axios.get(process.env.GATSBY_AIRCRAFT_SPECS_URL);
  const aircraftResponse = await fetchSpecs();

  const x = aircraftResponse.data.aircraft.map(el => {
    
    if(el.title === 'Airliner'){
      el.title = 'VIP Airliner'
    }

    return ({
      node: {
        title: el.title,
        specs_list: el
      }
     
    })

  });

  return x
};

export default fetchAircraftSpecs