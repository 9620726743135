import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import Layout from 'components/layout';

import SEO from 'SEO';

import { setPhoneNumber } from 'state/actions/phoneNumber';

// 96824 - this file incl preview changes

const RenderHomeWrapper = props => {
  useEffect(() => {
    props.setPhoneNumber(props.phoneNumber)
  }, [])

  return (
    <Layout
      location={props.location}
      isHomePage={true}
      language={props.language}
      alternateLanguages={props.alternateLanguages}
      localePhoneNumber={props.localePhoneNumber}
      universalProps={props.universalProps}
      errorMessages={props.errorMessages}
      icons={props.icons}
      footerProps={props.footerProps}
      headerData={props.headerData}
      preview={props.preview}
      previewHeaderData={props.headerData}
      previewFooterData={props.footerProps.node}
    >
      <React.Fragment>
        <SEO 
          meta={props.meta}
          location={props.location}
          isHomePage={true}
          language={props.language}
          alternateLanguages={props.preview ? [] : props.alternateLanguages}
          languageCodes={props.languageCodes}
          preview={props.preview}
          phoneNumber={props.phoneNumber}
        />
        {props.component}
      </React.Fragment>
    </Layout>
  );
};

const mapDispatchToProps = dispatch => ({
  setPhoneNumber: phoneNumber => dispatch(setPhoneNumber(phoneNumber))
})

export default connect(null, mapDispatchToProps)(RenderHomeWrapper);

RenderHomeWrapper.propTypes = {
  alternateLanguages: PropTypes.array,
  component: PropTypes.object,
  errorMessages: PropTypes.array,
  footerProps: PropTypes.object,
  headerData: PropTypes.object,
  icons: PropTypes.array,
  language: PropTypes.string,
  languageCodes: PropTypes.array,
  localePhoneNumber: PropTypes.array,
  location: PropTypes.object,
  meta: PropTypes.object,
  phoneNumber: PropTypes.string,
  preview: PropTypes.bool,
  setPhoneNumber: PropTypes.func,
  universalProps: PropTypes.array,
}