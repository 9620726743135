import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import RenderPostWrapper from "components/HOC/RenderPostWrapper";
import RichPost from "components/richText";

import CountryIndexPageTable from 'components/tables/CountryIndexPageTable'
import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

const CountryHome = ({ data, preview, location, pageContext }) => {

  const language = preview ? data.prismicCountryHome.lang : pageContext.lang;
  const alternateLanguages = preview ? data.prismicCountryHome.alternate_languages : pageContext.alternateLanguages;
  const content = data.prismicCountryHome.data
  const richContent = preview ? content.post : content.post.raw

  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;

  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);

  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;
  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const universalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);

  //language codes from header query for SEO component
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);
  
  let headData = {
    meta_title: content.meta_title,
    meta_description: content.meta_description,
    meta_keywords: content.meta_keywords,
  }

  // add open graph meta data if available
  if(content?.body2?.length > 0) {
    const filteredOpenGraphData = content.body2.filter(el => el.slice_type === "open_graph");

    if (filteredOpenGraphData?.length > 0) {
      const openGraphData = filteredOpenGraphData[0]?.primary;

      if (openGraphData?.og_title) {
        headData = {
          ...headData,
          og_title: openGraphData.og_title,
          og_description: openGraphData?.og_description || openGraphData?.og_title,
          og_image: openGraphData?.og_image?.url.split('?')[0] || defaultBannerImage.url.split('?')[0],
          og_type: openGraphData?.og_type || 'website',
        }
      }
    }
  }

  return (
    <RenderPostWrapper
      preview={preview}
      meta={headData}
      location={location}
      language={language}
      alternateLanguages={alternateLanguages}
      languageCodes={languageCodes}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      defaultBannerImage={defaultBannerImage}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      icons={data.allPrismicUniversalProperties.edges}
      footerProps={footerProps}
      sideBarProps={universalProps}
      flightSearchErrorMessages={errorMessages}
      universalProps={data.allPrismicUniversalProperties.edges}
      headerData={headerData}
      component={
        <React.Fragment>
          <RichPost
            richContent={richContent}
            slices={content.body1}
            preview={preview}
            language={language}
          />
          <CountryIndexPageTable 
            content={content}
            language={language}
          />
        </React.Fragment>
      }
    />
  );
};

CountryHome.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool
};

export default CountryHome;

export const query = graphql`
  query CountryHomeQuery($id: String!) {
    prismicCountryHome(id: { eq: $id }) {
      ...PrismicCountryHome
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
  }
`;
