// 96824 - this file incl preview changes

export const prismicTypes = [
  "properties_-_press_release",
  "properties_-_error_messages",
  "universal_-_properties",
  "universal_-_phone_number",
  "country_home",
  "aircraftproperties",
  "aircraft_home",
  "index_page_empty_legs",
  "flightsearchproperties",
  "properties_country",
  "form_-_flight_search",
  "footer_v2",
  "headermenu_v2",
  "aircraft_listing",
  "universal_-_banner_image",
  "universal_-_blog_properties"
]