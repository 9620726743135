import React, { Component } from 'react';
import PropTypes from "prop-types";
import Prismic from 'prismic-javascript';
import axios from 'axios';

import AircraftHome from 'templates/AircraftHome';

import { getAllAircraft } from '../functions/GetAllAircraft';
import getLinkedAircraft from './functions/LinkedAircraft';
import getLinkedCategory from './functions/LinkedCategory';
class AircraftHomePreview extends Component {
  constructor(props) {
    super(props)

    this.state = {
      response: this.props.data,
      specs: null,
      allAircraft: null,
      listing: null,
      listingAircrafts: null
    }
  }

    componentDidMount(){
      const apiEndpoint = 'https://PrivateFly.prismic.io/api/v2';
      const self = this;
      const category = this.state.response.data.aircraft_category

      Promise.all([
        axios.get(process.env.GATSBY_AIRCRAFT_SPECS_URL),
        Prismic.api(apiEndpoint).then(api => {
          return api.getSingle("aircraftproperties", { lang: "*" });
        }),
        getLinkedCategory(category, apiEndpoint),
        getLinkedAircraft(apiEndpoint, category)
      ])
      .then(results => {
        this.setState({
          specs: results[0],
          aircraftProps: results[1],
          listing: results[2],
          listingAircrafts: results[3]
        })
      });

      getAllAircraft(self) 
  }

  render() {
    const { response, specs, allAircraft, listing, listingAircrafts, aircraftProps } = this.state

    if (response && allAircraft && specs && listing && listingAircrafts && aircraftProps) {
      const allAir = allAircraft.map(el => ({ node: el }));

      response.data.aircraft_category = listing

        return (
          <AircraftHome
            data={{
               ...this.props,
              prismicAircraftHome: response,
              allPrismicAircraft: { edges: allAir },
              listing: listing,
              listingAircrafts: listingAircrafts,
              prismicAircraftproperties: aircraftProps,
            }} 
            location={this.props.location}
            preview={true}
          />
        )
    } else return <React.Fragment />
  } 
}

AircraftHomePreview.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default (AircraftHomePreview)