/**
 * This function accepts array of slices and returns a filtered slice
 * @author Andrew Bedziak
 * 
 * @param {slices} array - Array of slices
 * @param {sliceType} string - Type of slice
 * @param {returnAsArray} bool - Boolean to indicate if a return value should be an array or object

 * @returns {object} 
 */

export default function filterSlices (slices, sliceType, returnAsArray) {
  let slice;

  if (slices) {
    if (!returnAsArray) {
      slice = slices.find(el => {
        if (el.slice_type === sliceType) {
          return el
        }
      });
    } else {
      slice = slices.filter(el => el.slice_type === sliceType)
    }
  }

  return slice
}