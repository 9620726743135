import React from "react";
import PropTypes from "prop-types";
import loadable from '@loadable/component'
import LazyLoad from 'react-lazyload';
import { useStaticQuery, graphql } from "gatsby";
import Button from 'components/buttons/Main';

import RichPost from 'components/richText';
import SlicesWrapper from 'components/slices';

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

import { H1, H3 } from 'styled';

const LoadableMap = loadable(() => import( /* webpackChunkName: "gatsby-MultiMarkermap" */ 'components/maps/MultiMarkerMap'));

const isSwissDomain = process.env.IS_SWISS_DOMAIN;

const CountryContent = ({
  content,
  richContent,
  slices,
  preview,
  countryID,
  airfields,
  countryProps,
  countryIndexPageUrl,
  language,
  phoneNumber,
  fsExcludeFlag
}) => {
  let previewAirfields = [];
  let previewCountryProps;

  const isFrench = process.env.LANG_CODE === 'fr-fr' || language === 'fr-fr';

    const data = useStaticQuery(graphql`
      query previewCountryAirfields {
        allAirfieldsData {
          edges {
            node {
              id
              airfield_id
              icao_code
              iata_code
              airfield_name
              time_zone
              country_id
              country_name
              latitude
              longitude
            }
          }
        }
        allPrismicPropertiesCountry {
          edges {
            node {
              id
              lang
              data {
                meta_description
                meta_keywords
                meta_title
              }
            }
          }
        }
        allPrismicUniversalProperties {
            edges {
              node {
                lang
                data {
                  map_icon {
                    url
                  }
                }
              }
            }
          }
      }
  `)

  const filterLocaleAirfieldProps = filterPrismicDataByLanguage(data.allPrismicPropertiesCountry.edges, language);
  // need to inspect whether || en-gb causes issues with anything
  const mapProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language || 'en-gb'); 

  previewCountryProps = filterLocaleAirfieldProps.node.data;

  previewAirfields = data.allAirfieldsData.edges
    .filter(item => item.node.country_id == countryID.slice(1))
    .map(el => el.node)

  countryProps = preview ? previewCountryProps : countryProps; 
  const countryAirfields = preview ? previewAirfields : airfields;

  let markersArray = countryAirfields && countryAirfields.map(el => {
    return {
      id: el.airfield_id,
      latitude: el.latitude,
      longitude: el.longitude,
      airfield_name: el.airfield_name,
    }
  })

  return (
    <React.Fragment>
      
      {fsExcludeFlag ? (
        <div id="fs-exclude" style={{visibility: "hidden"}}> 
          {fsExcludeFlag} 
        </div>
        ) : null 
      }

      <React.Fragment>
        {!richContent ?
          <H1 fontSize="30px" fontWeight="400"> {countryProps.country_title} {content.country_name} </H1>
          : null
        }
        <RichPost richContent={richContent} language={language} />
      </React.Fragment>
      
      <SlicesWrapper
        slices={slices}
        preview={preview}
        telephoneNumber={phoneNumber}
      />

      { markersArray?.length ? (

        <div style={{marginBottom: '50px'}}>          
          <H3 
            fontSize="24px" 
            fontWeight="500" 
            paddingBottom="0.5em" 
            paddingTop="0.5em"
          >
            {content.country_name} {countryProps.airport_map_title}
          </H3>

          <LazyLoad 
            height={'400px'}
            offset={-50}
            once
          > 
            <LoadableMap 
              mapInputPlaceHolder={""}
              mapMarkerText={""}
              isCountryPage={true}
              countryPageAirfields={markersArray}
              countryIndexPageUrl={countryIndexPageUrl}
              mapIcon={mapProps.node.data.map_icon.url}
              language={language}
            />
          </LazyLoad>
        </div>

        ) : (
          <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <div style={{fontSize: '20px', marginBottom: '15px', fontWeight: '600'}}> 
              {isFrench ? 'Aucun aérodromes disponible !' : 'No Airfields Available!'} 
            </div>
            <Button 
              isLink={true}
              size="large"
              buttonText={isFrench ? `Retour à la page d'accueil du pays` : 'Back to Country Homepage'}
              buttonHref={`${isFrench ? `${isSwissDomain == "true" ? '/fr' : ''}/vol-prive/countries` : '/private-jet-hire/countries' }`} 
            />
          </div>
        ) 
      }

    </React.Fragment>
  )
}

export default CountryContent;

CountryContent.propTypes = {
  airfields: PropTypes.array,
  content: PropTypes.object,
  countryID: PropTypes.string,
  countryIndexPageUrl: PropTypes.string,
  countryProps: PropTypes.object,
  fsExcludeFlag: PropTypes.number,
  language: PropTypes.string,
  phoneNumber: PropTypes.string,
  preview: PropTypes.bool,
  richContent: PropTypes.array,
  slices: PropTypes.array,
};
