import Prismic from 'prismic-javascript'

import createProperties from 'utils/functions/createProperties';

const fetchPressReleaseDocuments = () => {
  const { prismicEndpoint } = createProperties();

  const apiEndpoint = 'https://privatefly.cdn.prismic.io/api/v2';
  const client = Prismic.client(prismicEndpoint);

  return Prismic
    .api(apiEndpoint)
    .then(api => api.query(Prismic.Predicates.at('document.type', 'press_release'), { pageSize: '100', lang: '*' }) )
    .then(response => {
      const pages = response.total_pages;

      const pagesNumber = [...Array(pages).keys()];

      return Promise.all(pagesNumber.map((el, i) => {
        const page = i +1
  
        return client
          .query(Prismic.Predicates.at('document.type', 'press_release'), { pageSize: '100', page: i, lang: '*' })
          .then(res => res.results)
          .catch(err => console.log(err))
        }))
        .then(array => [].concat(...array))
        .then(result => {
          return result.map(el => { return { node: el } }) 
        })
    })
    .catch(err => console.log(err))
}

export default fetchPressReleaseDocuments;