import React, {useState } from 'react';
import PropTypes from 'prop-types';
import Link from "gatsby-link";

import ArrowButton from 'components/buttons/ArrowButton';
import { StyledBlogCategoriesList, StyledBlogSmallHeading} from 'components/blog/styled';

const isFR = process.env.LANG_CODE === 'fr-fr';

const Categories = ({ data, heading }) => {

  const [categoryLinks, setCategoryLinks] = useState(10);
  const showLinks = (showMore) => { setCategoryLinks(categoryLinks + showMore) };

  let categoryCount = {};
  const categories = data.map(el => el.tags);

  const flattenedCategories = [].concat.apply([], categories);

  // sort categories list alphabetically
  flattenedCategories.sort((a, b)=>a.localeCompare(b))

  flattenedCategories.forEach(function(x) {
    categoryCount[x] = (categoryCount[x] || 0)+1; 
  });

  const categoriesArray = Object.entries(categoryCount);

  return (
    <div style={{marginTop: '40px'}}>
      <StyledBlogSmallHeading> {heading.list_heading} </StyledBlogSmallHeading>
      <StyledBlogCategoriesList>
        {categoriesArray.splice(0, categoryLinks).map(tag => {
          const categoryToDisplay = tag[0].replace(/-/g, " ");

          return (
            <li key={tag[0]}>
              <Link to={`/category/${tag[0]}`}> {categoryToDisplay} </Link> ({tag[1]})
            </li>
          );
        })}
        { categoryLinks < categoriesArray.length && (
        <ArrowButton 
          buttonText={`${isFR ? "AFFICHER PLUS" : "SHOW ALL"} ${heading.list_heading}`}
          theme="blue"
          isLink={false}
          onClick={()=>showLinks(categoriesArray.length)}
          style={{marginTop: '15px', justifyContent: 'right'}}
        /> 
        )}
      </StyledBlogCategoriesList>
    </div>
  );
};

Categories.propTypes = {
  data: PropTypes.object,
  heading: PropTypes.object
};

export default Categories;