import React from 'react'
import PropTypes from 'prop-types'

import RenderRichText from 'utils/richText/renderRichText';

import { StyledKeyFeaturesWrapper } from 'components/aircraft/KeyFeatures/styled';

function KeyFeatures({ slice, preview }) {
  const keyFeaturesText = preview ? slice.primary.widget_content : slice.primary.widget_content.raw;

  return (
    <StyledKeyFeaturesWrapper>
      <RenderRichText richTextArray={keyFeaturesText} />
    </StyledKeyFeaturesWrapper>
  )
}

KeyFeatures.propTypes = {
  preview: PropTypes.bool,
  slice: PropTypes.object
}

export default KeyFeatures