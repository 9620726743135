import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';

import SlicesWrapper from 'components/slices';
import RenderPostWrapper from 'components/HOC/RenderPostWrapper';
import RenderRichText from 'utils/richText/renderRichText';

// import { StyledPressReleaseSection } from 'styled';
import { switchLanguage } from 'gatsbyNode/utils/switchLanguage';

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';

import { centerParagraph } from 'components/richText/centerParagraph';
import { setPhoneNumber } from 'state/actions/phoneNumber';

const PressRelease = ({ location, data, preview, pageContext, setPhoneNumber }) => {
  const content = data.prismicPressRelease;
  const text = content.data.rich_text.raw;
  const alternateLanguages = preview ? content.alternate_languages : pageContext.alternateLanguages;
  const language = preview ? content.lang : pageContext.lang;

  centerParagraph(text, "-Ends-", "- Ends -");
  
  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  //Hero Image default banner image
  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;
  
  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;
  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const universalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);

  //language codes from header query for SEO component
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);
  
  // press release properties for sidebar widget
  const pressReleaseProperties = filterPrismicDataByLanguage(data.allPrismicPropertiesPressRelease.edges, language);
  const hasWidget = preview ? data?.allPrismicWidgetData?.edges?.length > 0 : pressReleaseProperties?.node?.data?.body?.length > 0;
  const widgetData = preview ? data?.allPrismicWidgetData?.edges : pressReleaseProperties?.node?.data?.body;

  let headData = {
    meta_title: content.data.meta_title,
    meta_description: content.data.meta_description,
    meta_keywords: content.data.meta_keywords,
  }

  useEffect(() => {
    setPhoneNumber(phone_local)
  }, [])

  // add open graph meta data if available
  if(content?.data?.body?.length > 0) {
    const filteredOpenGraphData = content.data.body.filter(el => el.slice_type === "open_graph");

    if (filteredOpenGraphData?.length > 0) {
      const openGraphData = filteredOpenGraphData[0]?.primary;

      if (openGraphData?.og_title) {
        headData = {
          ...headData,
          og_title: openGraphData.og_title,
          og_description: openGraphData?.og_description || openGraphData?.og_title,
          og_image: openGraphData?.og_image?.url.split('?')[0] || defaultBannerImage.url.split('?')[0],
          og_type: openGraphData?.og_type || 'website',
        }
      }
    }
  }

  return (
    <RenderPostWrapper 
      data={content.data}
      location={location}
      meta={headData}
      language={language}
      alternateLanguages={alternateLanguages}
      heroImage={{heroImage: null}}
      languageCodes={languageCodes}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      defaultBannerImage={defaultBannerImage}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      icons={universalProps}
      footerProps={footerProps}
      sideBarProps={universalProps}
      flightSearchErrorMessages={errorMessages}
      universalProps={universalProps}
      headerData={headerData}
      preview={preview}
      widget={hasWidget ? widgetData : []} 
      component={
        <React.Fragment>
          <article data-wio-id={preview ? content.id : null}>
            <RenderRichText richTextArray={text && text} />
          </article>
          <SlicesWrapper 
            slices={content.data.body1 ? content.data.body1 : null} 
            preview={preview} 
            telephoneNumber={phone_local}
          />
        </React.Fragment>
      }
    /> 
  );
}

const mapDispatchToProps = dispatch => ({
  setPhoneNumber: phoneNumber => dispatch(setPhoneNumber(phoneNumber))
})

export default connect(null, mapDispatchToProps)(PressRelease);

PressRelease.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool,
  setPhoneNumber: PropTypes.func,
}

export const query = graphql`
  query PressReleaseQuery($id: String!) {
    prismicPressRelease(id: { eq: $id }) {
      ...PrismicPressRelease
    }
    allPrismicPropertiesPressRelease {
      edges {
        node {
          ...PrismicPropertiesPressRelease
        }
      }
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
  }
`;