import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from 'prop-types';
import InternalLink from "components/links/internalLink";

import CategoriesFilter from 'components/blog/filters/Categories'
import MonthFilter from 'components/blog/filters/Months'
import { switchLanguage } from 'gatsbyNode/utils/switchLanguage';
// import RichPost from 'components/richText';

import { StyledBlogsSmallColumnWrapper } from 'components/blog/styled';

const CategoriesList = ({ showHomepageLink }) => { 
  const data = useStaticQuery(graphql`
    query CategoryQuery {
      allPrismicPost {
        edges {
          node {
            lang
            id
            uid
            type
            tags
            fields {
              year
              month
              yearMonth
            }
            data {
              date_published
              url
              author_link {
                url
                document {
                  id
                  data {
                    author_name
                    url
                  }
                }
              }
            }
          }
        }
      }
      allPrismicUniversalBlogProperties {
        edges{
          node {
            lang
            data {
              filters_list_heading {
                list_heading
              }
            }
          }
        }
      }
    }
  `)

  const allPrismicBlogPosts = switchLanguage(data.allPrismicPost.edges);
  const allPrismicBlogProperties = switchLanguage(data.allPrismicUniversalBlogProperties.edges);
  
  const postData = allPrismicBlogPosts.filter(el => el.uid !== "test-document").map(el => el.node);
  const sideBarListsHeadings = allPrismicBlogProperties.map(el => el.node.data.filters_list_heading)[0];

  const blogHomepageLinkComponent = ( 
    <div style={{color: '#4990e2', fontWeight: '600', marginBottom: '30px', textDecoration: 'underline', fontSize:'12px'}}> 
      BLOG HOME 
    </div> 
  );

  return (
    <StyledBlogsSmallColumnWrapper> 
      { showHomepageLink && <InternalLink path="/" linkComponent={blogHomepageLinkComponent} /> }
      <MonthFilter data={postData} heading={sideBarListsHeadings[0]} />
      <CategoriesFilter data={postData} heading={sideBarListsHeadings[1]} />
    </StyledBlogsSmallColumnWrapper>
  );
}

CategoriesList.propTypes = {
  showHomepageLink: PropTypes.bool
}

export default CategoriesList;