import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SEO from 'SEO';

import Banner from 'components/banner';
import ContentNavigation from 'components/navigation';
import HomepageFlightSearch from 'components/flightSearch/container';
import SideBarWidget from 'components/widgets';
import Button from 'components/buttons/Main';
import Layout from 'components/layout';

import { ColumnLarge, FullWidthTwoColumnContainer, StyledSidebarColumn } from 'components/grid/styled';

import RenderRichText from 'utils/richText/renderRichText';

const RenderPressAndMediaHomeWrapper = props => {

  // all variables for banner component
  const bannerImageSrc = props.banner.primary.image.url;
  const displayHeadingOneAsParagraph = props.banner.primary.display_heading1_as_paragraph
  const textPosition = props.banner.primary.text_position;
  
  const headingOneRichText = props.banner.primary.heading1.length > 0 && props.preview ? props.banner.primary.heading1 : 
    props.banner.primary.heading1.raw;
  
    const headingTwoRichText = props.banner.primary.heading2 ? props.banner.primary.heading2.length > 0 && props.preview ? props.banner.primary.heading2 
      : props.banner.primary.heading2.raw 
      : [];

  const headingThreeRichText = props.banner.primary.heading3 ? props.banner.primary.heading3.length > 0 && props.preview ? props.banner.primary.heading3 
    : props.banner.primary.heading3.raw 
    : [];

  const paragraphRichText = props.banner.primary.banner_text ? props.banner.primary.banner_text.length > 0 && props.preview ? props.banner.primary.banner_text[0].raw 
    : props.banner.primary.banner_text.raw 
    : [];
    
  const isBottomBanner = props.banner.primary.display_banner_at_the_bottom;
  const bannerImageSize = props.banner.primary.banner_image_size

  return (
    <Layout
      location={props.location}
      language={props.language}
      alternateLanguages={props.alternateLanguages}
      localePhoneNumber={props.localePhoneNumber}
      universalProps={props.universalProps}
      errorMessages={props.errorMessages}
      icons={props.icons}
      footerProps={props.footerProps}
      headerData={props.headerData}
      preview={props.preview}
      previewHeaderData={props.headerData}
      previewFooterData={props.footerProps.node}
    >
      <SEO 
        meta={props.meta}
        location={props.location}
        language={props.language}
        alternateLanguages={props.preview ? [] : props.alternateLanguages}
        languageCodes={props.languageCodes}
        preview={props.preview}
        phoneNumber={props.phoneNumber}
      />

      <Banner 
        desktopLarge={bannerImageSize === "large" ? true: false}
        desktopMedium={bannerImageSize === "medium" ? true: false}
        desktopSmall={bannerImageSize === "small" ? true: false}
        isHomePage={false}
        headingOne={headingOneRichText && <RenderRichText richTextArray={headingOneRichText} />}
        headingTwo={headingTwoRichText && <RenderRichText richTextArray={headingTwoRichText} />}
        headingThree={headingThreeRichText && headingThreeRichText && <RenderRichText richTextArray={headingThreeRichText} />}
        displayHeadingOneAsParagraph={displayHeadingOneAsParagraph}
        textPosition={textPosition}
        bannerImageSrc={bannerImageSrc}
        text={paragraphRichText && <RenderRichText richTextArray={paragraphRichText} />}
        isBottomBanner={isBottomBanner}
      />

      <ContentNavigation 
        anchors={props.anchorNavigation.items}
        isTwoColumnLayout={true}
      /> 

      <FullWidthTwoColumnContainer justifyContent="space-between">
        
        <ColumnLarge>
          {props.component} 
        </ColumnLarge>

        <StyledSidebarColumn hasWidget={props.widget && props.widget.length > 0}>
          { props.widget && props.widget.length > 0 ? ( 
            props.widget.map(el => {

              const widgetData = props.preview ? el.data : el.primary.widget_document.document[0].data;
                      
              const widgetType = widgetData.widget_type;
              const widgetButtonText = widgetData.widget_button_text;     
              const widgetButtonLink = widgetData.widget_button_link.url;

              return (
                <SideBarWidget 
                  preview={props.preview}
                  widgetType={widgetType}
                  widgetData={widgetData} 
                  swissPhoneNumber={props.phoneNumber}
                  buttonComponent={
                    <Button 
                      className="widget-cta-button"
                      language={props.language}
                      isLink={true}
                      size="large"
                      buttonText={widgetButtonText}
                      buttonHref={widgetButtonLink}
                      margin="0"
                      width="100%"
                    />
                  }
                />
              )

            })
          ) : (
            <HomepageFlightSearch 
              language={props.language}
              errorMessages={props.fsErrorMessages}
              sideBarProps={props.sideBarProps}
              isWidget={true}
            />
          )}
        </StyledSidebarColumn>

      </FullWidthTwoColumnContainer>

    </Layout>
  );
};

const mapDispatchToProps = dispatch => ({
  setPhoneNumber: phoneNumber => dispatch(setPhoneNumber(phoneNumber))
})

export default connect(null, mapDispatchToProps)(RenderPressAndMediaHomeWrapper);

RenderPressAndMediaHomeWrapper.propTypes = {
  alternateLanguages: PropTypes.array,
  anchorNavigation: PropTypes.object,
  banner: PropTypes.object,
  component: PropTypes.object,
  errorMessages: PropTypes.array,
  footerProps: PropTypes.object,
  fsErrorMessages: PropTypes.array,
  headerData: PropTypes.object,
  icons: PropTypes.array,
  language: PropTypes.string,
  languageCodes: PropTypes.array,
  localePhoneNumber: PropTypes.array,
  location: PropTypes.object,
  meta: PropTypes.object,
  phoneNumber: PropTypes.string,
  preview: PropTypes.bool,
  sideBarProps: PropTypes.object,
  universalProps: PropTypes.array,
  widget: PropTypes.array
}
