import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';

import Banner from 'components/banner';
import RenderRichText from 'utils/richText/renderRichText';
import Card from 'components/card';
import ArrowButton from 'components/buttons/ArrowButton';
import MediaQuery from 'components/mediaQuery'

import { FullWidthCardContainer, FullWidthContainer } from 'components/grid/styled';

import { StyledHomepageMobileFlightSearchWrapper } from 'components/slices/styled';

const SlicesWrapper = ({ 
  slices, 
  preview, 
  language, 
  isHomePage, 
  flightSearchComponent 
}) => {  
  return (
    <React.Fragment>
      {slices ? 
        slices.map((slice, index) => {
          const sliceType = slice.slice_type;

          return (
            <React.Fragment key={slice.id}>
              {(() => {
                switch (sliceType) {
                  case "banner_image": 

                    const displayHeadingOneAsParagraph = slice.primary.display_heading1_as_paragraph
                    const headingOneRichText = slice.primary.heading1.length > 0 && preview ? slice.primary.heading1 : slice.primary.heading1.raw;
                    const headingTwoRichText = slice.primary.heading2.length > 0 && preview ? slice.primary.heading2 : slice.primary.heading2.raw;
                    const headingThreeRichText = slice.primary.heading3.length > 0 && preview ? slice.primary.heading3 : slice.primary.heading3.raw;
                    const textPosition = slice.primary.text_position;
                    const bannerImageSrc = slice.primary.image.url;
                    const bannerImageSize = slice.primary.banner_image_size
                  
                    return (
                      <Banner 
                        desktopLarge={bannerImageSize === "large" ? true: false}
                        desktopMedium={bannerImageSize === "medium" ? true: false}
                        desktopSmall={bannerImageSize === "small" ? true: false}
                        isHomePage={isHomePage}
                        headingOne={headingOneRichText && <RenderRichText richTextArray={headingOneRichText} />}
                        headingTwo={headingTwoRichText && <RenderRichText richTextArray={headingTwoRichText} />}
                        headingThree={headingThreeRichText && <RenderRichText richTextArray={headingThreeRichText} />}
                        displayHeadingOneAsParagraph={displayHeadingOneAsParagraph}
                        textPosition={textPosition}
                        bannerImageSrc={bannerImageSrc}
                        flightSearchComponent={ 
                          <Media query="(min-width: 1200px)">
                            {matches =>
                              matches ? flightSearchComponent : null
                            }
                          </Media>}
                      />
                    )

                case "flight_search": 
                  return (
                    <MediaQuery 
                      mobileComponent={
                        <StyledHomepageMobileFlightSearchWrapper>
                          {flightSearchComponent}
                        </StyledHomepageMobileFlightSearchWrapper>
                      }
                      desktopComponent={null}
                      viewPort={1200}
                    />
                  ) 

                  case 'richtext': 
                    const richTextArray = preview ? slice.primary.text : slice.primary.text.raw;

                    return (
                      <FullWidthContainer isHomePage={true}>
                        <RenderRichText richTextArray={richTextArray} />
                      </FullWidthContainer>
                    )

                  case 'card_section': 
                    return (
                      <FullWidthCardContainer marginBottom="40px">
                        {
                          slice.items.map(el => {
                            const cardButtonText = el.card_button;
                            const cardText = preview ? el.card_text : el.card_text.raw;
                            const cardTitle = preview ? el.card_title : el.card_title.raw;
                            const cardImageUrl = el.card_image.url;
                            const cardLink = el.card_link.url;

                            return (
                              <Card 
                                buttonComponent={
                                  <ArrowButton 
                                    buttonText={cardButtonText}
                                    buttonHref={cardLink}
                                    theme="red"
                                    isLink={true}
                                    language={language}
                                  />
                                }
                                buttonAlign="center"
                                cardText={<RenderRichText richTextArray={cardText} />}
                                headingText={<RenderRichText richTextArray={cardTitle} />}
                                cardImageUrl={cardImageUrl}
                                hasSeperator={true}
                              />
                            )
                          })
                        }
                      </FullWidthCardContainer>
                  )
                  default: 
                    return <React.Fragment />
                }
              })()}
            </React.Fragment>
          )
        })
      : null}
    </React.Fragment>
  )
};

SlicesWrapper.propTypes = {
  flightSearchComponent: PropTypes.object,
  isHomePage: PropTypes.bool,
  language: PropTypes.string,
  preview: PropTypes.bool,
  slices: PropTypes.array
};

export default SlicesWrapper;
