import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Aircraft from 'templates/Aircraft';
import Airfield from 'templates/Airfield';
import Product from 'templates/ProductPage';
import BlogArticle from 'templates/BlogArticle';
import Country from 'templates/Country';
import Inspirational from 'templates/Inspirational';
import IndexInspirational from 'templates/InspirationalIndex.js';
import Other from 'templates/Other';
import PressRelease from 'templates/PressRelease'; 
import Team from 'templates/Team';
import CountryHome from 'templates/CountryHome';
import HomePage from 'templates/index';
import SearchResultsPage from 'templates/Search';
import SiteMap from 'templates/SiteMap';
// import InspirationalIndexPage from 'templates/IndexInspirational';
import ErrorPage from 'templates/Error';
import PressAndMediaIndexPage from 'templates/PressAndMediaIndexPage';

import Layout from 'components/layout';
import AircraftListingPreview from 'components/preview/aircraftCat';
import AircraftHomePreview from 'components/preview/aircraftHome';
import DestinationsHome from 'templates/Destinations';

import getPrismicData from 'components/preview/functions/getPrismicData';
import fetchInspirationalDocuments from 'components/preview/functions/fetchInspirationalDocuments';
import fetchFeaturedInspirationalDocuments from 'components/preview/functions/fetchFeaturedInspirationalDocuments';
import fetchPressReleaseDocuments from 'components/preview/functions/fetchPressReleaseDocuments';
import fetchDestinationsDocuments from 'components/preview/functions/fetchDestinationsDocuments';
import fetchProductFeaturedAircraft from 'components/preview/functions/fetchProductFeaturedAircraft';
import fetchWidgetData from 'components/preview/functions/fetchWidgetData';
import fetchAircraftSpecs from 'components/preview/functions/fetchAircraftSpecs';
import { fetchOpeningTimes } from 'components/preview/functions/fetchOpeningTimes';

import { prismicTypes } from 'components/preview/prismicTypes';

// 96824 - this file incl preview changes

class PreviewWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.id,
      isResponseFetched: false,
      allPrismicInspirational: {}
    }
  }

  componentDidMount() {
    const b = getPrismicData(prismicTypes);
    const c = fetchInspirationalDocuments();
    const d = fetchFeaturedInspirationalDocuments(this.props.previewProps);
    const e = fetchPressReleaseDocuments();
    const f = fetchDestinationsDocuments(this.props.previewProps);
    const g = fetchOpeningTimes();
    const h = fetchProductFeaturedAircraft(this.props.previewProps);
    const i = fetchWidgetData(this.props.previewProps);
    const j = fetchAircraftSpecs();

    Promise.all([b, c, d, e, f, g, h, i, j]).then(res => {
      if (res) {
        let prismicDocument = res[0];

        this.setState({ 
          isResponseFetched: true,
          prismicPropertiesPressRelease: prismicDocument.find(el => el.node.type === "properties_-_press_release").node, 
          prismicUniversalProperties: prismicDocument.find(el => el.node.type === "universal_-_properties").node, 
          prismicUniversalPhoneNumber: prismicDocument.find(el => el.node.type === "universal_-_phone_number").node, 
          prismicAircraftHome: prismicDocument.find(el => el.node.type === "aircraft_home").node, 
          prismicUniversalBannerImage: prismicDocument.find(el => el.node.type === "universal_-_banner_image").node, 

          allPrismicAircraftHome: { edges : prismicDocument.filter(el => el.node.type === "aircraft_home") }, 
          allPrismicUniversalProperties: { edges : prismicDocument.filter(el => el.node.type === "universal_-_properties") }, 
          allPrismicUniversalPhoneNumber: { edges: prismicDocument.filter(el => el.node.type === "universal_-_phone_number") }, 
          allPrismicPropertiesErrorMessages: { edges : prismicDocument.filter(el => el.node.type === "properties_-_error_messages") }, 
          allPrismicFlightsearchproperties: { edges : prismicDocument.filter(el => el.node.type === "flightsearchproperties") }, 
          allPrismicAircraftproperties: { edges : prismicDocument.filter(el => el.node.type === "aircraftproperties") }, 
          allPrismicUniversalBlogProperties: { edges: prismicDocument.filter(el => el.node.type === "universal_-_blog_properties") }, 
          allPrismicAircraftListing: { edges : prismicDocument.filter(el => el.node.type === "aircraft_listing") },
          allPrismicCountryHome: { edges: prismicDocument.filter(el => el.node.type === "country_home") }, 
          allPrismicPropertiesCountry: { edges: prismicDocument.filter(el => el.node.type === "properties_country") },
          allPrismicHeadermenuV2: { edges : prismicDocument.filter(el => el.node.type === "headermenu_v2") }, 
          allPrismicFooterV2: { edges : prismicDocument.filter(el => el.node.type === "footer_v2") },
          allPrismicPropertiesPressRelease: { edges : prismicDocument.filter(el => el.node.language='en-xx' && el.node.type === "properties_-_press_release") },
          allPrismicInspirational: { edges: res[1] },
          featuredInspirationalIndexCards: res[2],
          allPrismicPressRelease: { edges: res[3] },
          allAirfieldOpeningTimes: { edges : res[5] }, 
          allProductFeaturedAircraft: { edges : res[6] }, 
          allPrismicWidgetData: { edges : res[7] }, 
          allAircraftSpecs: { edges: res[8] }
        })
      }
    }).catch(err => console.log(err))
  }

  renderPreviewComponents = () => {
    const type = this.props.type;

    let richTextArray = []

    let documentProps = this.props.previewProps.data.data;

    if (documentProps.data.post) {
      documentProps.data.post.forEach(el => richTextArray.push(el))
      documentProps.data.post.raw = richTextArray
    }

    if (documentProps.data.rich_text) {
      documentProps.data.rich_text.forEach(el => richTextArray.push(el))
      documentProps.data.rich_text.raw = richTextArray
    }
    
    if (type) {
      switch (type) {
        case 'country' :
          return (
            <Country 
              data={{ prismicCountry: documentProps, ...this.state }}
              preview={true}
              location={this.props.location}
            />
          )
        case 'press_release' :
          return (
            <PressRelease 
              data={{ prismicPressRelease: documentProps, ...this.state }}
              preview={true}
              location={this.props.location}
            />
          ) 
        case 'country_home' :
          return (
            <CountryHome 
              location={this.props.location} 
              data={{ prismicCountryHome: this.props.previewProps.data.data, ...this.state}}
              preview={true}
            />
          )
        case 'aircraft' : 
          return (
            <Aircraft 
              data={{prismicAircraft: this.props.previewProps.data, ...this.state }} 
              preview={true}
              location={this.props.location}
            />
          )
        case 'airfield' :
          return (
            <Airfield 
              data={{prismicAirfield: this.props.previewProps.data.data, ...this.state }} 
              preview={true}
              location={this.props.location}
            />
          )

        case 'product' :
          return (
            <Product 
              data={{prismicProduct: this.props.previewProps.data.data, ...this.state }} 
              preview={true}
              location={this.props.location}
            />
          )
        case 'post' :
          return (
            <BlogArticle 
              data={{prismicPost:this.props.previewProps.data.data, ...this.state}} 
              preview={true}
              location={this.props.location} 
            />  
          )
        case 'inspirational' : 
          return (
            <Inspirational 
              data={{prismicInspirational:this.props.previewProps.data.data, ...this.state}} 
              location={this.props.location} 
              preview={true}
            />
          )
        case 'index_inspirati' : 
          return (
            <IndexInspirational 
              data={{prismicIndexInspirati:this.props.previewProps.data.data, ...this.state}} 
              location={this.props.location} 
              preview={true}
            />
          )
        case 'other' :
          return (
            <Other 
              data={{prismicOther:this.props.previewProps, ...this.state}} 
              location={this.props.location} 
              preview={true}
            />  
          )
        case 'about_team' :
          return (
            <Team 
              data={{ prismicAboutTeam: this.props.previewProps.data.data, ...this.state }} 
              preview={true}
              location={this.props.location}
            />
          )
        case 'index_page_press_and_media' :
          return (
            <PressAndMediaIndexPage
              data={{ prismicIndexPagePressAndMedia: documentProps, ...this.state }}
              preview={true} 
              location={this.props.location}
            />
          )
        case 'destinations' :
          return (
            <DestinationsHome
              data={{ prismicDestinations: this.props.previewProps.data.data, ...this.state }} 
              preview={true}
              location={this.props.location}
            />
          )
        case 'aircraft_listing' :
          return (
            <AircraftListingPreview
              {...this.props.previewProps.data}
              {...this.state}
              preview={true}
              location={this.props.location}
            />
          )
        case 'aircraft_home' :
            return (
              <AircraftHomePreview 
                preview={true} 
                {...this.props.previewProps.data} 
                {...this.state}
                location={this.props.location}
              />
            )
        case 'homepage' :
          return (
            <HomePage 
              data={{
                prismicHomepage: this.props.previewProps.data.data, 
                allPrismicUniversalProperties: this.state.allPrismicUniversalProperties,
                ...this.state
              }} 
              preview={true}
              location={this.props.location}
            />
          )
        case 'site_map' :
          return (
            <SiteMap 
              data={{prismicSiteMap: this.props.previewProps.data.data, ...this.state}} 
              preview={true}
              location={this.props.location}
            />
          )
        case 'error_page_index' :
          return (
            <ErrorPage 
              data={{prismicErrorPageIndex : this.props.previewProps, ...this.state}}
              preview={true}
              location={this.props.location}
            />
          )
        case 'site_search' :
          return (
            <SearchResultsPage
              {...this.state}  
              data={{ prismicSiteSearch: this.props.previewProps.data.data, ...this.state}} 
              preview={true} 
              location={this.props.location}
            />
          )
        case 'headermenu_v2' :
          return (
            <Layout 
              location={this.props.location} 
              previewHeaderData={this.props.previewProps.data.data}
              previewFooterData={this.state.allPrismicFooterV2.edges[0].node}
              preview={true}
            >
              <div style={{height: '50vh', marginLeft:"17%", marginRight:"17%", fontWeight:"bold", paddingTop:"20vh", textAlign:"center"}}>
                Header layout preview
              </div>
            </Layout>
          )

        case 'footer_v2' :
            return (
              <Layout 
                location={this.props.location} 
                previewFooterData={this.props.previewProps.data.data}
                previewHeaderData={this.state.allPrismicHeadermenuV2.edges[0].node}
                preview={true}
              >
                <div style={{height: '50vh', marginLeft:"17%", marginRight:"17%", fontWeight:"bold", paddingTop:"20vh", textAlign:"center"}}>
                  Footer layout preview
                </div>
              </Layout>
            )
        default : 
          return (
            <Layout 
              location={this.props.location} 
              preview={true}
              previewFooterData={this.state.allPrismicFooterV2.edges[0].node}
              previewHeaderData={this.state.allPrismicHeadermenuV2.edges[0].node}
            >
              <div style={{height: '50vh', marginLeft:"17%", marginRight:"17%", fontWeight:"bold", paddingTop:"20vh", textAlign:"center"}}>
                <p> This document type is not available for preview.</p>
                <p> Please select from one of the other "Marketing" preview options in Prismic </p>
                <p> If neither one of the Marketing previews work for this document, please try another document type, else contact the Tech Team. </p>
                <div style={{marginTop: '20px', fontSize: '11px'}}> Note: Previews for any properties, widget or flight search documents will not work by themselves, to preview these please preview a page they are featured on. </div>
              </div>
            </Layout>
          )
      }
    } 
    else return (
      <span>loading</span>
    )
  }
  
  render() {
    if (this.state.isResponseFetched) {
      return (
        <React.Fragment>
          {this.renderPreviewComponents()}
        </React.Fragment>
      )
    } else return null
  }
}

PreviewWrapper.propTypes = {
  id: PropTypes.string,
  location: PropTypes.object,
  previewProps: PropTypes.object,
  type: PropTypes.string
}

export default PreviewWrapper;