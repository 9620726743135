import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import AircraftContent from 'components/aircraft';
import RenderPostWrapper from 'components/HOC/RenderPostWrapper';

import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';
import replaceVariablesFromPrismic from 'utils/functions/replaceVariablesFromPrismic';
import replaceLocalePhoneNumber from 'utils/functions/replaceLocalePhoneNumber';
import filterSlices from 'utils/functions/filterSlices';

const Aircraft = ({ data, location, pageContext, preview }) => {

  const [ keyFeaturesText, setKeyFeaturesText ] = useState("");
  const [ aircraftName, setAircraftName ] = useState("");

  useEffect(() => {
    const list = document.querySelector("article ul");
    const keyFeaturesH3 = document.querySelectorAll("article h3");

    const h3List = Array.from(keyFeaturesH3)

    h3List.forEach(el => {
      if (el.textContent.indexOf("Features") > -1 || el.textContent.indexOf("Atouts") > -1) {
        
        el.classList.add("key-features-h3")
      }

      if (el.textContent.indexOf("Overview Summary") > -1 || el.textContent.indexOf("Présentation") > -1) {
        el.id = "overview"
      }

      if (el.textContent.indexOf("Comfort & Size") > -1 || el.textContent.indexOf("Taille et confort à bord") > -1) {
        el.id = "comfort&size"
      }
    })

    if (list) {
      if (list.previousElementSibling) {
        list.previousElementSibling.classList.add("key-features-previous-sibling")
      }
      
      list.classList.add("key-features-list");

      setKeyFeaturesText(list.innerHTML);
    }

   !preview && setAircraftName(data.prismicAircraft.data.url.replace("-", " "));
  }, [])

  let aircraftMetaData;
  let aircraftId;
  let localisedAircraftProperties;
  let phoneNumber;
  let headData;
  let aircraftSpecifications;
  
  const language = preview ? data.prismicAircraft.data.lang : pageContext.lang || process.env.LANG_CODE;
  const alternateLanguages = preview ? data.prismicAircraft.data.alternate_languages : pageContext.alternateLanguages;

  const content = preview ? data.prismicAircraft.data : data.prismicAircraft;
  const richText = preview ? content.data.post : content.data.post.raw;
  const aircraft = content?.data?.url?.replace(/-/gi, " ");
  const slices = content.data.body1;

  const { node: headerData } = filterPrismicDataByLanguage(data.allPrismicHeadermenuV2.edges, language);
  //Hero Image default banner image
  const filteredUniversalPhoneNumber = filterPrismicDataByLanguage(data.allPrismicUniversalPhoneNumber.edges, language);
  const { node: {data: { phone_local }}} = filteredUniversalPhoneNumber;
  const {node: {data: aircraftProps }} = filterPrismicDataByLanguage(data.allPrismicAircraftproperties.edges, language);
  
  const defaultBannerImage = data.prismicUniversalBannerImage.data.banner_image;
  const errorMessages = filterPrismicDataByLanguage(data.allPrismicPropertiesErrorMessages.edges, language);
  const footerProps = filterPrismicDataByLanguage(data.allPrismicFooterV2.edges, language);
  const universalProps = filterPrismicDataByLanguage(data.allPrismicUniversalProperties.edges, language);
  const gallerySlice = filterSlices(slices, "gallery_slice", true);
  const filteredSlices = slices && slices.filter(el => el.slice_type !== "gallery_slice");

  //language codes from header query for SEO component
  const languageCodes = footerProps.node.data.language.map(el => el.country_code);

  if (!preview) {
    localisedAircraftProperties = filterPrismicDataByLanguage(data.allPrismicAircraftproperties.edges, language)
    phoneNumber = replaceLocalePhoneNumber(data.allPrismicUniversalPhoneNumber.edges, language);

    if (localisedAircraftProperties) {
      if (
        process.env.LANG_CODE !== "en-gb" && 
        localisedAircraftProperties.node.lang === process.env.LANG_CODE && 
        data.prismicAircraft.lang !== process.env.LANG_CODE
      ) {
  
        aircraftMetaData = replaceVariablesFromPrismic(localisedAircraftProperties.node.data, phoneNumber, null, null, null, aircraft)
      }
    }
  }

  if (content.data.aircraft_id !== null) {
    aircraftId = content.data.aircraft_id;
    aircraftSpecifications = data.allAircraftSpecs.edges
    .find(el => {
      if (el.node.specs_list.id) {
        if (el.node.specs_list.id == Number(aircraftId)) return el
      }
    })
  }

  if (aircraftMetaData) {
    headData = {
      meta_title: !preview && aircraftMetaData.title,
      meta_description: !preview && aircraftMetaData.description,
      meta_keywords: !preview && aircraftMetaData.keywords,
    }
  } else {
    headData = {
      meta_title: content.data.meta_title,
      meta_description: content.data.meta_description,
      meta_keywords: content.data.meta_keywords,
    }
  }

  const bannerImageSlice = filterSlices(slices, "banner_image", false);
  const keyFeaturesWidget = filterSlices(slices, "widget", false);

  const isFR = process.env.LANG_CODE === 'fr-fr' || language === 'fr-fr';

  // check if there is tableOfContentsSlice otherwise fallback to anchors
  const tableOfContentsSlice = slices && slices.filter(el => el.slice_type === "table_of_contents");

  const anchors = [
    { article_anchor_text: isFR ? "Présentation" : "Overview", article_anchor_hash: "overview" },
    { article_anchor_text: isFR ? "Taille et confort" : "Comfort & Size", article_anchor_hash: "comfort&size" },
    { article_anchor_text: isFR ? "Caractéristiques" : "Specification", article_anchor_hash: "specification" },
    { article_anchor_text: isFR ? "Photos" : "Gallery", article_anchor_hash: "gallery" }
  ];
  
  const checkOtherWidgets = keyFeaturesWidget && keyFeaturesWidget.items && keyFeaturesWidget.items.filter(el => el.other_widgets && el);

  let otherWidgets

  if(preview) {
    otherWidgets = data?.allPrismicWidgetData?.edges

  } else if (checkOtherWidgets?.length > 0) {
    otherWidgets = checkOtherWidgets.map(el => {
      return el.other_widgets.document[0]
    })
  }

  // add open graph meta data if available
  if(content?.data?.body2?.length > 0) {
    const filteredOpenGraphData = content.data.body2.filter(el => el.slice_type === "open_graph");

    if (filteredOpenGraphData?.length > 0) {
      const openGraphData = filteredOpenGraphData[0]?.primary;

      if (openGraphData?.og_title) {
        headData = {
          ...headData,
          og_title: openGraphData.og_title,
          og_description: openGraphData?.og_description || openGraphData?.og_title,
          og_image: openGraphData?.og_image?.url.split('?')[0] || defaultBannerImage.url.split('?')[0],
          og_type: openGraphData?.og_type || 'website',
        }
      }
    }
  }

  return (
    <RenderPostWrapper
      bannerImageSlice={bannerImageSlice}
      tableOfContentsSlice={tableOfContentsSlice && tableOfContentsSlice.length > 0 ? tableOfContentsSlice : anchors}
      meta={headData}
      heroImage={content.data.hero_image}
      location={location}
      preview={preview}
      language={language}
      alternateLanguages={alternateLanguages}
      languageCodes={languageCodes}
      localePhoneNumber={data.allPrismicUniversalPhoneNumber.edges}
      phoneNumber={phone_local}
      defaultBannerImage={defaultBannerImage}
      errorMessages={data.allPrismicPropertiesErrorMessages.edges}
      icons={universalProps}
      footerProps={footerProps}
      sideBarProps={universalProps}
      flightSearchErrorMessages={errorMessages}
      universalProps={universalProps}
      headerData={headerData}
      keyFeaturesWidget={keyFeaturesWidget}
      isAircraftTemplate={true}
      aircraftName={keyFeaturesText && aircraftName}
      keyFeaturesText={keyFeaturesText}
      widget={otherWidgets && otherWidgets}
      maxColLargeWidth="60%"
      component={
        <AircraftContent 
          richContent={richText}
          preview={preview}
          language={language}
          aircraftId={aircraftId}
          aircraftProps={aircraftProps}
          gallerySlice={gallerySlice}
          filteredSlices={filteredSlices}
          aircraftSpecifications={aircraftSpecifications}
          isAircraftTemplate={true}
        />
      }
    />
  );
};

export default Aircraft;

Aircraft.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object,
  preview: PropTypes.bool,
};

export const query = graphql`
  query aircraftQuery($id: String!) {
    prismicAircraft(id: { eq: $id }) {
      ...Aircraft
      ...GallerySlice
      ...InsetImageSlice
    }
    allPrismicAircraftproperties {
      edges {
        node {
          ...PrismicAircraftproperties
        }
      }
    }
    allPrismicHeadermenuV2 {
      edges {
        node {
          ...HeaderData
        }
      }
    }
    prismicUniversalBannerImage {
      data {
        banner_image {
          url
        }
      }
    }
    allPrismicUniversalPhoneNumber {
      edges {
        node {
          ...PrismicUniversalPhoneNumber
        }
      }
    }
    allPrismicFooterV2 {
      edges {
        node {
          ...FooterData
        }
      }
    }
    allPrismicFlightsearchproperties {
      edges {
        node {
         ...PrismicFlightsearchproperties
        }
      }
    }
    allPrismicUniversalProperties {
      edges {
        node {
          ...PrismicUniversalProperties
        } 
      }
    }
    allPrismicPropertiesErrorMessages {
      edges {
        node {
          ...PrismicPropertiesErrorMessages
        }
      }
    }
    allAircraftSpecs {
      edges {
        node {
          ...AircraftSpecs
        }
      }
    }
  }
`;
