import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Layout from 'components/layout';
import TeamCard from 'components/slices/layout/teamCard';

import SEO from 'SEO';

import { GridRow, GridColumn } from 'components/grid/styled';
import Banner from 'components/banner';

import { setPhoneNumber } from 'state/actions/phoneNumber';

// 96824 - this file incl preview changes

const RenderTeamWrapper = props => {
  useEffect(() => {
    props.setPhoneNumber(props.phoneNumber)
  }, [])

  return (
    <Layout
      location={props.location}
      language={props.language}
      alternateLanguages={props.alternateLanguages}
      localePhoneNumber={props.localePhoneNumber}
      headerData={props.headerData}
      footerProps={props.footerProps}
      errorMessages={props.errorMessages}
      universalProps={props.universalProps}
      icons={props.icons}
      preview={props.preview}
      previewHeaderData={props.headerData}
      previewFooterData={props.footerProps.node}
    >
      <SEO 
        meta={props.meta}
        location={props.location}
        language={props.language}
        alternateLanguages={props.preview ? [] : props.alternateLanguages}
        languageCodes={props.languageCodes}
        preview={props.preview}
        phoneNumber={props.phoneNumber}
      />
      <Banner 
        desktopLarge={false}
        desktopMedium={true}
        desktopSmall={false}
        isHomePage={false}
        isTemplate={true}
        bannerImageSrc={props.heroImage && props.heroImage.url ? props.heroImage.url : props.defaultBannerImage.url}
      />
      {props.leadership && 
        <GridRow 
          backgroundColor='#46344E'
          paddingBottom='30px'
          paddingTop='30px'
        >
          <GridColumn>
            <TeamCard 
              data={props.leadership}
              preview={props.preview}
              leadership={true}
            />
          </GridColumn>
        </GridRow>
        }
      <GridRow paddingTop='1em'>
        <GridColumn>
          {props.component}
        </GridColumn>
      </GridRow>
    </Layout>
  );
};

const mapDispatchToProps = dispatch => ({
  setPhoneNumber: phoneNumber => dispatch(setPhoneNumber(phoneNumber))
})

export default connect(null, mapDispatchToProps)(RenderTeamWrapper);

RenderTeamWrapper.propTypes = {
  alternateLanguages: PropTypes.array,
  component: PropTypes.object,
  defaultBannerImage: PropTypes.object,
  errorMessages: PropTypes.array,
  footerProps: PropTypes.object,
  headerData: PropTypes.object,
  heroImage: PropTypes.object,
  icons: PropTypes.array,
  language: PropTypes.string,
  languageCodes: PropTypes.array,
  leadership: PropTypes.object,
  localePhoneNumber: PropTypes.array,
  location: PropTypes.object,
  meta: PropTypes.object,
  phoneNumber: PropTypes.string,
  preview: PropTypes.bool,
  setPhoneNumber: PropTypes.func,
  universalProps: PropTypes.array,
}
