import Prismic from 'prismic-javascript';

import createProperties from 'utils/functions/createProperties';

// 96824 - this file incl preview changes

export default function getPrismicData(prismicTypes) {
  const { prismicEndpoint } = createProperties();
  
  return Prismic
  .api(prismicEndpoint)
  .then(api => api.query(Prismic.Predicates.any("document.type", prismicTypes), { pageSize : 50 }))
  .then(res => {
    return res.results.map(el => { return { node: el } });
  })
  .catch(err => {
      console.log(err)
    })
}