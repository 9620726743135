import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/buttons/button';
import InternalLink from 'components/links/internalLink';

import RenderRichText from 'utils/richText/renderRichText';
import { H1 } from 'styled';
function PreviewSearchComponent({ 
  siteSearchData, 
  resultsParagraph, 
  ctaButton,
  parsedUrl, 
  language, 
  noResultsParagraph  
}) {
  return (
    <React.Fragment>
      <div className="search-results-container">
        <H1 fontWeight="500" fontSize="30px">{siteSearchData.page_title.text}</H1>
        <RenderRichText richTextArray={resultsParagraph} />
        {ctaButton ?
          <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
            <InternalLink 
              path={`/${parsedUrl.pathname}`} 
              language={language}
              style={{color: "rgba(0, 0, 0, 0.65)", fontWeight: 400}}
              linkComponent={
                <Button 
                  fontColor="white"
                  marginBottom='0'
                  padding='13px 40px'
                  fontSize='14px'
                  fontWeight='500'
                  text={ctaButton.button_name}
                />
              }
            />
          </div>
        : null }
      </div>
      <div className="search-results-container">
        <H1 fontWeight='500' fontSize="30px">{siteSearchData.page_title.text}</H1>
        <RenderRichText richTextArray={noResultsParagraph} />         
      </div>
    </React.Fragment>
  )
}

PreviewSearchComponent.propTypes = {
  ctaButton: PropTypes.object,
  language: PropTypes.string, 
  noResultsParagraph: PropTypes.array,
  parsedUrl: PropTypes.object, 
  resultsParagraph: PropTypes.array,
  siteSearchData: PropTypes.object, 
}

export default PreviewSearchComponent

